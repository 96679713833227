export const currentUser = (state: { auth: { user: mbb.model.IUser } }) => {
  return state.auth.user
}

export const currentUserScreenName = (state: { auth: { screenName: string } }) => {
  return state.auth.screenName;
};

export const getCurrentUsername = (state: { auth: { username: string } }) => {
  return state.auth.username;
}

export const getToken = (state: { auth: { authTokens: string } }) => {
  return state.auth.authTokens;
}