import debug from "debug";

// we use this to be able to see logs only for dev in the dev tools console
//Format Log.info(response object/"my message", "some general info about where it came from")
//Format Log.error(error object/"my message", "registration error event:")

const BASE = "bb-frontend-dev";
const COLOURS = {
  trace: "lightblue",
  info: "blue",
  warn: "pink",
  error: "red",
}; // choose better colours :)

class Log {
  generateMessage(level, message, source) {
    // Set the prefix which will cause debug to enable the message
    const namespace = `${BASE}:${level}`;
    const createDebug = debug(namespace);

    createDebug.enabled = true;

    // Set the colour of the message based on the level
    createDebug.color = COLOURS[level];

    //only show logs in dev environment

    if (
      process.env.REACT_APP_STAGE !== "production" &&
      process.env.NODE_ENV !== "dev"
    ) {
      if (source) {
        createDebug(source, message);
      } else {
        createDebug(message);
      }
    }
  }

  trace(message, source) {
    return this.generateMessage("trace", message, source);
  }

  info(message, source) {
    return this.generateMessage("info", message, source);
  }

  warn(message, source) {
    return this.generateMessage("warn", message, source);
  }

  error(message, source) {
    return this.generateMessage("error", message, source);
  }
}

export default new Log();
