import * as React         from 'react';
import Snackbar           from '@mui/material/Snackbar';
import Alert              from '@mui/material/Alert';
import Slide              from '@mui/material/Slide';
import { useDispatch }    from 'react-redux';
import { removeMessage }  from 'redux/ui/uiActions';

interface ISnackBarAlertProps {
    UIMessage: mbb.model.IUIMessage
}


function TransitionLeft(props: any) {
  return <Slide {...props} direction="left"/>;
}

const SnackBarAlert = ({UIMessage}: ISnackBarAlertProps) => {
  const dispatch = useDispatch();
  const removeCurrentMessage = () => {
    dispatch(removeMessage())
  }
  return (
    <Snackbar
        TransitionComponent={TransitionLeft}
        open={true}
        autoHideDuration={5000}
        onClose={removeCurrentMessage}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
    >
      <Alert variant='filled' severity={UIMessage.severity ? UIMessage.severity : 'success'}>
        {UIMessage.message}
      </Alert>
    </Snackbar>
  );
}
export default SnackBarAlert