import { AnyAction } from 'redux'
import actions from 'redux/actionTypes.json'

type IEarings = mbb.state.IEarnings;

const initialState: IEarings = {
 report: [],
 lastMonthsEarnings: undefined,
 thisMonthsEarnings: undefined,
 transactionHistory: [],
 error: ""
}

const assetEarningsReducer = (
  state: IEarings = initialState,
  action: AnyAction
): IEarings => {
  switch (action.type) {
    case actions.earnings.SET_REPORT:
      return {
        ...state,
        report: action.report,
      }
    case actions.earnings.SET_LAST_MONTH:
      return {
        ...state,
        lastMonthsEarnings: action.lastMonthsEarnings,
      }
    case actions.earnings.SET_THIS_MONTH:
      return {
        ...state,
        thisMonthsEarnings: action.thisMonthsEarnings,
      }
    case actions.earnings.SET_TRANSACTION_HISTORY:
      return{
        ...state,
        transactionHistory: action.transHistory
      }
    default:
      return state
  }
}

export default assetEarningsReducer
