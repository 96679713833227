import React, {lazy} from 'react'
import { Route, Routes} from 'react-router-dom'
import routes from 'common/constants/routes.json'
import { AppliedRoute } from './utils/routeUtils'
import TemplateDetails from 'pages/TemplatesPage/components/TemplateDetails'
import UploadTemplateFiles from 'pages/TemplatesPage/components/UploadTemplateFiles'
import UploadImages, { ImageCategory } from 'pages/TemplatesPage/components/UploadImages'
import TemplatePrice from 'pages/TemplatesPage/components/TemplatePrice'

// Pages
const Details = lazy(() => import("./pages/DashboardPage/components/Details"))
const Preview = lazy(() => import("./pages/DashboardPage/components/Preview"))
const Price = lazy(() => import("./pages/DashboardPage/components/Price"))
const Upload = lazy(() => import("./pages/DashboardPage/components/Upload"))
const Dashboard = lazy(() => import("./pages/DashboardPage/DashboardContainer"))
const Login = lazy(() => import("./pages/LandingPage/LandingContainer"))
const ForgetPassword = lazy(() => import("./pages/LandingPage/components/ForgetPassword"))
const SignUp = lazy(() => import("./pages/LandingPage/components/SignUp"))
const ConfirmationCode = lazy(() => import("./pages/LandingPage/components/ConfirmationCode"))
const ScreenNameSetup = lazy(() => import("./pages/LandingPage/components/ScreenNameSetup"))
const SettingsContainer = lazy(() => import("./pages/SettingsPage/SettingsContainer"))
const ProfileContainer = lazy(() => import("./pages/ProfilePage/ProfileContainer"))
const MyEarningsContainer = lazy(() => import("./pages/MyEarningsPage/MyEarningsContainer"))
const SelectedAssetContainer = lazy(() => import("./pages/DashboardPage/SelectedAssetContainer"))
const ClientUpgradeRedirect = lazy(() => import("pages/LandingPage/clients/ClientUpgradeRedirect") );
const ClientLoginRedirect = lazy(() => import("./pages/LandingPage/clients/ClientLoginRedirect"));
const ClientLogoutRedirect = lazy(() => import("./pages/LandingPage/clients/ClientLogoutRedirect"));
const ClientPostmessageConnection = lazy(() => import("./pages/LandingPage/clients/ClientPostmessageConnection"));
const ClientGoogleLogin = lazy(() => import("./pages/LandingPage/clients/ClientGoogleLogin"));
const ClientFacebookLogin = lazy(() => import("./pages/LandingPage/clients/ClientFacebookLogin"));
const ClientAppleLogin = lazy(() => import("./pages/LandingPage/clients/ClientAppleLogin"));
const ClientAwaitingFacebookLogin = lazy(() => import("./pages/LandingPage/clients/ClientAwaitingFacebookLogin"));
const ClientAwaitingAppleLogin = lazy(() => import("./pages/LandingPage/clients/ClientAwaitingAppleLogin"));
const ClientAwaitingGoogleLogin = lazy(() =>import("./pages/LandingPage/clients/ClientAwaitingGoogleLogin"));
const TemplatesContainer = lazy(() => import("./pages/TemplatesPage/TemplatesContainer"));
const SelectedTemplateContainer = lazy(() => import("./pages/TemplatesPage/SelectedTemplateContainer"))

function PageRoutes() {
  return (
    <Routes>
      <Route
        path={routes.LANDING_PAGE}
        element={
          <AppliedRoute protectedRoute={false} >
             <Login />
          </AppliedRoute>
        }
      />
      <Route
        path={routes.LOGIN}
        element={
          <AppliedRoute protectedRoute={false}>
            <Login />
          </AppliedRoute>
        }
      />
      <Route
        path={routes.FORGET_PASSWORD}
        element={
          <AppliedRoute protectedRoute={false}>
            <ForgetPassword />
          </AppliedRoute>
        }
      />

      <Route
        path={routes.SIGN_UP}
        element={
          <AppliedRoute protectedRoute={false}>
            <SignUp />
          </AppliedRoute>
        }
      />
       <Route
        path={routes.CONFIRM_SIGNUP}
        element={
          <AppliedRoute protectedRoute={false}>
            <ConfirmationCode />
          </AppliedRoute>
        }
      />
      <Route
        path={routes.SCREENNAME_SET_UP}
        element={
          <AppliedRoute protectedRoute={true}>
            <ScreenNameSetup />
          </AppliedRoute>
        }
      />
      <Route
        path={routes.DASHBOARD}
        element={
          <AppliedRoute protectedRoute={true}>
            <Dashboard />
          </AppliedRoute>
        }
      />
      <Route path={routes.DASHBOARD_SELECTED_ASSET} element={ 
        <AppliedRoute protectedRoute={true}> 
          <SelectedAssetContainer/> 
        </AppliedRoute>} >   
        <Route path={`${routes.DASHBOARD_SELECTED_ASSET}/details`} element={<Details />} />
        <Route path={`${routes.DASHBOARD_SELECTED_ASSET}/upload`} element={<Upload/>} />
        <Route path={`${routes.DASHBOARD_SELECTED_ASSET}/preview`} element={<Preview />} />
        <Route path={`${routes.DASHBOARD_SELECTED_ASSET}/price`} element={<Price />} />
      </Route>
        
        //FIXME: LIKE ASAP
      {/* <AppliedRoute
        path={routes.CLIENT_AWAITING_GOOGLE_LOGIN}
        component={ClientAwaitingGoogleLogin}
      />
      <AppliedRoute
        path={routes.CLIENT_AWAITING_FACEBOOK_LOGIN}
        component={ClientAwaitingFacebookLogin}
      />
      <AppliedRoute
        path={routes.CLIENT_AWAITING_APPLE_LOGIN}
        component={ClientAwaitingAppleLogin}
      /> */}
      <Route
        path={routes.CLIENT_REDIRECT}
        element={
        <AppliedRoute protectedRoute={false}>
          <ClientLoginRedirect />
        </AppliedRoute>}
      />
      <Route
        path={routes.CLIENT_LOGOUT_REDIRECT}
        element={
        <AppliedRoute protectedRoute={false}>
          <ClientLogoutRedirect />
        </AppliedRoute>}
      />
      <Route
        path={routes.REDIRECT}
        element={
          <AppliedRoute protectedRoute={false}>
            <ClientUpgradeRedirect />
          </AppliedRoute>}
      />
      <Route
        path={routes.CLIENT_POSTMESSAGE_CONNECTION}
        element={
          <AppliedRoute protectedRoute={false}>
            <ClientPostmessageConnection />
          </AppliedRoute>}
      />

      <Route
        path={routes.CLIENT_GOOGLE_LOGIN}
        element={
          <AppliedRoute protectedRoute={false}>
            <ClientGoogleLogin />
          </AppliedRoute>}
      />
      <Route
        path={routes.CLIENT_FACEBOOK_LOGIN}
        element={
          <AppliedRoute protectedRoute={false}>
            <ClientFacebookLogin />
          </AppliedRoute>}
      />
      <Route
        path={routes.CLIENT_APPLE_LOGIN}
        element={
          <AppliedRoute protectedRoute={false}>
            <ClientAppleLogin />
          </AppliedRoute>}
      />
      <Route
        path={routes.CLIENT_AWAITING_GOOGLE_LOGIN}
        element={
          <AppliedRoute protectedRoute={false}>
            <ClientAwaitingGoogleLogin />
          </AppliedRoute>}
      />
      <Route
        path={routes.CLIENT_AWAITING_FACEBOOK_LOGIN}
        element={
          <AppliedRoute protectedRoute={false}>
            <ClientAwaitingFacebookLogin />
          </AppliedRoute>}
      />
      <Route
        path={routes.CLIENT_AWAITING_APPLE_LOGIN}
        element={
          <AppliedRoute protectedRoute={false}>
            <ClientAwaitingAppleLogin />
          </AppliedRoute>}
      />
      <Route path={routes.PROFILE} element={
        <AppliedRoute protectedRoute={true}>
          <ProfileContainer />
        </AppliedRoute>} />
      <Route path={routes.MY_EARNINGS} element={
        <AppliedRoute protectedRoute={true}>
          <MyEarningsContainer />
        </AppliedRoute>} />
    <Route path={routes.PAYMENT_SETTINGS} element={
        <AppliedRoute protectedRoute={true}>
          <SettingsContainer />
        </AppliedRoute>
      } />
      <Route path={routes.TEMPLATES} element={
        <AppliedRoute protectedRoute={true}>
          <TemplatesContainer />
        </AppliedRoute>
      } />
      <Route path={routes.SELECTED_TEMPLATE} element={ 
        <AppliedRoute protectedRoute={true}> 
          <SelectedTemplateContainer/> 
        </AppliedRoute>} >
      <Route path={`${routes.SELECTED_TEMPLATE}/details`} element={<TemplateDetails />} />
      <Route path={`${routes.SELECTED_TEMPLATE}/upload`} element={<UploadTemplateFiles/>} />
      <Route path={`${routes.SELECTED_TEMPLATE}/preview-images`} element={<UploadImages imageCategory={ImageCategory.carousel} />} />
      <Route path={`${routes.SELECTED_TEMPLATE}/preview-gif`} element={<UploadImages imageCategory={ImageCategory.gif} />} />
      <Route path={`${routes.SELECTED_TEMPLATE}/price`} element={<TemplatePrice />} />
        </Route>
    </Routes>
  )
}

export default PageRoutes
