import React, { 
  useEffect, 
  Suspense 
} from 'react';
import { 
  Navigate, 
  Outlet 
} from 'react-router-dom'
// import { currentUser }      from 'redux/auth/authSelectors';
import useUserIsLoggedIn    from 'common/hooks/useUserIsLoggedIn';
import PageContainer        from 'common/container/PageContainer';
import { useSelector } from 'react-redux';
import { currentUserScreenName } from 'redux/auth/authSelectors';
import routes  from 'common/constants/routes.json';
import Log from './Log';
import HeaderLogo from 'assets/images/BB_Shop.png'
import styles from "styles/Routes.module.scss"
import { CircularProgress } from '@mui/material';
// import { getLoadingState } from 'redux/ui/uiSelectors';
interface IAuthRouteProps {
    path: string;
}

interface IAppliedRouteProps {
  Component?: React.ReactNode;
  path?: string;
  protectedRoute: boolean;
}

// FIXME: Find a better methods
// use routes here instead.\
// Decide whether the Protected Routes or AuthenticatedRoutes is the play here
// Make this into one unit, do a mix of Protected and auth, and make it easier to read
export const AuthenticatedRoutes: React.FC<IAuthRouteProps> = ({children, ...props }) => {
  // const userLoggedIn = useUserIsLoggedIn();

  // const { 
  //   path = '/'
  // } = props;

  return (
    <Suspense fallback={<div style={{ height: "100vh" }}>Loading...</div>}>
      {/* { !userLoggedIn ? <RedirectRoute link={path}/> : */}
          <PageContainer childComp={children}/>
      {/* } */}
   </Suspense>
  )
};


// TODO: Use this method with 3 return options for rerouting the user back or 
// props options, auth route T/F, component/children,  
// PageContainer component for the Auth routes
//
export const AppliedRoute:React.FC<IAppliedRouteProps> = ({children, ...props}) => {
  const { 
    // Component,
    // path,
    protectedRoute,
  } = props;
  const userLoggedIn = useUserIsLoggedIn();
  const screenName = useSelector(currentUserScreenName);
  // const loading = useSelector(getLoadingState)
  useEffect(() => {
    //to bring to top of page since react-router remembers your scroll pos?
    window.scrollTo(0, 0);
  });


  // Maybe use 
  const routesServer = () => {

    // protected route and user isn't signed in, make them login
    if(protectedRoute && !userLoggedIn){
      return  <Navigate replace to={routes.LOGIN} />
    }
    
    // protected signed and user is signed in, but screen name
    // is empty, must create a ScreeName
    if(protectedRoute && userLoggedIn && screenName === ""){
      return <> {children} </>;
    }

    // unProtected route and signed in, push them to dasshbaord
    if(!protectedRoute && userLoggedIn){
      Log.info("Unprotected " );
      return <Navigate replace to={routes.DASHBOARD} />
    }

    if(!protectedRoute){
      return <> {children} </>;
    }

    // default case, 
    return (<PageContainer childComp={children}/>);
  }

  return (
    <Suspense fallback={<div style={{ height: "100vh", width: "100vw" }}>
      <div className={styles.container}>
        <div className={styles.headerItemsLoading}>
          <div>
            <img src={HeaderLogo} alt='logo' />
            <div className={styles.loadingCircularContainer}>
              <div><CircularProgress color='warning' /></div> 
            </div>
          </div>
        </div>
        </div>
    </div>}>
      { routesServer() }
    </Suspense>
  );
}

//
export const ProtectedRoutes = () => {
  const userLoggedIn = useUserIsLoggedIn();

  return userLoggedIn ? <Outlet/> : <Navigate replace to="/" />;
}
 
