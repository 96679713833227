import API from '@aws-amplify/api'
import Log from "../utils/Log";

/**
 * creates user and returns active subscription information
 * @param {token}: string
 * @param {email}: string
 */

//dispatch

export const createUser = (token: string): any => {
  return new Promise((resolve, reject) => {
    API.post(`userURL`, '/webclientlogin', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        // "Content-Type": "application/json", why did this work? .... f corsÍ›
        Token: token,
      },
      response: true, //needs this to get the whole response object
    })
      .then((res) => {
        //200 indicates a returning user

        if (res.status === 200) {
          resolve({ ...res.data, newUser: false })
        }
        //201 indicates a new registration so we need to hit GA
        else if (res.status === 201) {
          resolve({ ...res.data, newUser: true })
          //send registration event
        }
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data.error)
        }
      })
  })
}

export const getUserAttributes = async (accessToken: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(
      `https://cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/`,
      {
        headers: {
          'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser',
          'Content-Type': 'application/x-amz-json-1.1',
        },
        mode: 'cors',
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify({
          AccessToken: accessToken,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Log.info("data from getUserAttributes", data);
        const newObj: any = {}

        data.UserAttributes.forEach((obj: any) => {
          const values: any = Object.values(obj)
          const key = values[0]
          newObj[key] = values[1]
        })

        resolve({ username: data.Username, ...newObj })
      })
      .catch((error) => {
        // Log.error(error, "error caught in while calling getUserAttributes");
        reject(error)
      })
  })
}

export const fetchUserScreenName = (accessToken: string): any => {
  return new Promise((resolve, reject) => {
    API.get(`bbworldUrl`, '/account', {
      headers: {
        'Content-Type': 'application/json',
        Token: accessToken,
      },
      response: true,
    })
      .then((res) => {
        resolve(res.data.screenName)
      })
      .catch((error) => {
        // Log.error(error, "error caught getting user screen name");
        reject(error.response)
      })
  })
}

export const fetchTokensforCode = (token: string): any => {
  return new Promise((resolve, reject) => {
    API.get(`userURL`, `/tokenLogin?token=${token}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200) {
          const ssoTokenResponse = res.data

          resolve(ssoTokenResponse)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// export const storeSSOTokens = (
//     clientId,
//     clientSecret,
//     accessToken,
//     refreshToken,
// ) => {
//     return new Promise((resolve, reject) => {
//         API.post(`devURL`, `/ssoTokens`, {
//             body: {
//                 clientId: clientId,
//                 clientSecret: clientSecret,
//                 accessToken: accessToken,
//                 refreshToken: refreshToken
//             },
//             headers: {
//                 "Content-Type": "application/json",
//                 Token: accessToken,
//             },
//             response: true,
//         })
//         .then((res) => {
//             if(res.status === 200 || res.status === 201) {
//                 const ssoTokenResponse = res.data.payload;

//                 const { id } = ssoTokenResponse;

//                 resolve(id);
//             }
//         })
//         .catch((error) => {

//             reject(error);
//         })
//     })
// }

export const updateUserScreenName = (
  accessToken: string,
  screenName: string
): Promise<string | any> => {
  return new Promise((resolve, reject) => {
    API.post(`bbworldUrl`, '/account/screenname', {
      body: {
        screenName,
      },
      headers: {
        'Content-Type': 'application/json',
        Token: accessToken,
      },
      response: true,
    })
    .then((res) => {
      resolve(res.data.payload.screenName);
    })
    .catch((error) => {
      Log.error(error, "error caught getting user screen name");
      reject(error.response.data);
    })
  });
}