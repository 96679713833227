import React, { useState, useCallback, FunctionComponent } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  Paper
} from '@mui/material';
import styles from 'styles/Dropzone.module.scss';
import imgIconUpload from 'assets/images/60x60/Upload Image.svg';
import { useSelector, useDispatch } from 'react-redux';
import { currentScreenShotImage, currentThumbNailImage } from 'redux/asset/assetSelectors'
import { uploadPublicResource } from 'redux/asset/assetThunks'
import Log from 'utils/Log';
import { getImageUPloadingState } from 'redux/ui/uiSelectors';
import CircularLoading from '../Loading/Circular';
import { currentTemplateCarousel, currentTemplatePreviewGif } from 'redux/template/templateSelectors';
import { ImageCategory } from 'pages/TemplatesPage/components/UploadImages';
import { uploadTemplateResource } from 'redux/template/templateThunks';
import { TemplateResourceType } from 'api/templates';


//TODO: Add images handler, and whether it's for a file, assets, for an image
// hover X: to delete image from the lot
// require the user to move the items out
// Lets use different types of drop zones or hande type of accepted files 
export enum DropZoneItem {
  asset = 'asset',
  template = 'template',
}
interface DropZoneProps {
  item: DropZoneItem;
  imageCategory?: ImageCategory;
}

const responsiveCarousel = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

// https://react-dropzone.js.org/
const DropZone: FunctionComponent<DropZoneProps> = ({ item, imageCategory }) => {
  const imageUrl = useSelector(currentScreenShotImage)
  // const imageUrls = useSelector(currentTemplateCarousel)
  const gifImage = useSelector(currentTemplatePreviewGif);
  const dispatch = useDispatch();
  const imageLoading = useSelector(getImageUPloadingState)

  const onDrop = useCallback(acceptedFiles => {

  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept:  item === 'asset' ? ['image/jpeg','image/png','image/bmp'] : ['image/gif','image/jpeg','image/png'],
    onDrop: (acceptedFiles: any) => {
      if (item === 'asset') {
        dispatch(uploadPublicResource(acceptedFiles[0], 'screenshot')) 
      } else {
        imageCategory === 'carousel' ? dispatch(uploadTemplateResource(acceptedFiles, TemplateResourceType.CAROUSEL_IMAGE)) : dispatch(uploadTemplateResource(acceptedFiles, TemplateResourceType.PREVIEW_GIF))
      }
    },
  })

  const { ref, ...rootProps } = getRootProps()

  const getImage = (): { itemExist: boolean,src: string} => {

    let imageSrc = "";
    let itemExist = false
    switch(item){
      case "asset":
        itemExist= imageUrl === null ? false : true;  
        imageSrc = imageUrl
        break;
      case "template":
         if (imageCategory === "carousel" ){
            itemExist= true 
            imageSrc = imgIconUpload
          } else {
            itemExist=  gifImage ? true : false ;
            imageSrc = gifImage
          }
        break;
      default:
        break;
    }

    return {itemExist, src: imageSrc}
  }

  return (
    <Paper {...rootProps} ref={ref} className={styles.container}>
      <input {...getInputProps()} />
      {/* <div className={styles.removeImageContainer}>
        <div className={styles.removeImageCircleContainer} onClick={handleRemoveImage}>
          <img src={xIcon} className={styles.removeImage}/>
        </div>
      </div> */}

      {     
        getImage().itemExist && !imageLoading ?  <img className="staticGif" alt="asset-media" src={ getImage().src}  style={ imageCategory === 'carousel' ? {filter: "invert(99%) sepia(0%) saturate(471%) hue-rotate(238deg) brightness(117%) contrast(78%)", position: "absolute" } : { height: "125px", width: "160px", borderRadius:"5px" }}/>  : 

        imageLoading ? <CircularLoading /> :
          <img alt="template-g" src={imgIconUpload} className={styles.imageUploadIcon} />
      }
      <div className={styles.mainTextContainer} >
        <div className={styles.mainTextContent}>
          <div className={styles.mainTextBox}>
            <p>{item === 'template' && imageCategory === 'carousel' ? 'Upload' : 'Main' }</p>
          </div>
        </div>
      </div>
    </Paper>
  )
}


export const DropZoneThumbnail = () => {
  const imageUrl = useSelector(currentThumbNailImage)
  const dispatch = useDispatch();

  //TODO: change accepeted files
  const onDrop = useCallback(acceptedFiles => {

  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles: any) => {
      dispatch(uploadPublicResource(acceptedFiles[0], 'thumbnail'))
    },
  })

  const { ref, ...rootProps } = getRootProps()
  const handleRemoveImage = () => {
    Log.info("handleRemoveImage");
  }

  return (
    <Paper {...rootProps} ref={ref} className={styles.container}>
      <input {...getInputProps()} />
      {/* <div className={styles.removeImageContainer}>
        <div className={styles.removeImageCircleContainer} onClick={handleRemoveImage}>
          <img src={xIcon} className={styles.removeImage}/>
        </div>
      </div> */}
      {/* TODO: add the arrow and the main at the button of the image */}
      {imageUrl ?
        <img src={imageUrl} style={{ height: "125px", width: "160px" }} /> :
        <img src={imgIconUpload} className={styles.imageUploadIcon} />
      }
      {/* <div className={styles.mainTextContainer} >
        <div className={styles.mainTextContent}>
          <div className={styles.mainTextBox}>
            <p>Thumbnail</p>    
          </div>
        </div>
      </div> */}
    </Paper>
  )
}

export default DropZone