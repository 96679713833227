import routes from './routes.json';
import dashboardIcon from 'assets/images/16x16/Dashboard.svg';
import earningsIcon from 'assets/images/16x16/Earnings.svg';
import profileIcon from 'assets/images/16x16/Profile.svg';
import settingsIcon from 'assets/images/16x16/Settings.svg';

export const drawerWidth = 256;

export const navItems = [
  {
    title: "Assets",
    path: routes.DASHBOARD,
    icon: dashboardIcon
  },
  {
    title: "Templates",
    path: routes.TEMPLATES,
    icon: dashboardIcon
  },
  {
    title: "My Earnings",
    path: routes.MY_EARNINGS,
    icon: earningsIcon,
  },
  {
    title: "Creator Profile",
    path: routes.PROFILE,
    icon: profileIcon,
  },
  {
    title: "Payment Settings",
    path: routes.PAYMENT_SETTINGS,
    icon: settingsIcon
  }
] 