import React from 'react'
import { useSelector } from 'react-redux'
import {currentUser} from 'redux/auth/authSelectors';

const useUserIsLoggedIn = () => {
  const user = useSelector(currentUser)
  return user !== null;
}

export default useUserIsLoggedIn
