import GenericButton from "common/components/Buttons/GenericButton";
import SubmitButton from "common/components/Buttons/SubmitButton";
import DropZone, { DropZoneItem } from "common/components/Dropzone/GenericDropZone";
import GenericToolTip from "common/components/ToolTip/GenericToolTip";
import AssetHeaderButtons from "pages/DashboardPage/components/AssetHeaderButtons";
import { FunctionComponent, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currentSelectedTemplate } from "redux/template/templateSelectors";
import { saveDraftAuthoredTemplate, deleteCarouselImage as deleteCarouselImageAPI } from "redux/template/templateThunks";
import styles from 'styles/TemplatePreview.module.scss';
import buttonHeaderStyles from 'styles/SelectedTemplatePage.module.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../../../styles/rrc.css";
import { Carousel } from 'react-responsive-carousel';
import GenericConfirmationModal from "common/components/Modals/GenericDeleteModal";
import { DeleteCarouselImageBody } from "api/templates";
import LoopIcon from '@mui/icons-material/Loop';
import GenericModal from "common/components/Modals/GenericModal";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { setCurrentTemplate, setSaveCurrentTemplate } from "redux/template/templateActions";
import { IconButton, Tooltip } from "@mui/material";
import xIcon from "assets/images/12x12/X.svg"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { assetValidateForSaveDraft } from "utils/ValidateFormUtils";

export enum ImageCategory {
  carousel = 'carousel',
  gif = 'gif'
}
interface UploadImagesProps {
  imageCategory: ImageCategory
}

type CarouselEntity = mbb.model.CarouselEntity

const modalContainerStyles = {
  minWidth: '560px',
  minHeight: '300px',
  transform: 'none',
  left: '25%',
  top: '25%'
}

const UploadImages: FunctionComponent<UploadImagesProps> = ({ imageCategory }) => {
  const currTemplate = useSelector(currentSelectedTemplate)
  // const imageUrls = useSelector(currentTemplateCarousel)?.sort((a, b) => a.Order - b.Order)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [deleteImageBody, setDeleteImageBody] = useState<DeleteCarouselImageBody>({} as DeleteCarouselImageBody)
  const [openReOrder, setOpenReOrder] = useState(false)
  const lastImageInit = currTemplate?.AssetStoreItemPageMedia?.Carousel?.length > 1 ? currTemplate?.AssetStoreItemPageMedia?.Carousel?.length - 1 : 0;
  
  const [selectedItem, setSelectedItem] = useState(lastImageInit);

  useEffect(() => {
    
    setDeleteImageBody({
      Id: currTemplate?.Id,
      TemplateUrl: currTemplate?.AssetStoreItemPageMedia?.Carousel[0]?.Url || '',
      Order: currTemplate?.AssetStoreItemPageMedia?.Carousel[0]?.Order
    })

    const lastImage = currTemplate?.AssetStoreItemPageMedia?.Carousel?.length > 1 ? currTemplate?.AssetStoreItemPageMedia?.Carousel?.length - 1 : 0;
    
    setSelectedItem(lastImage);
  }, [currTemplate])

  const getDispNameAndUrl = () => {
    if (imageCategory === 'carousel') {
      return {
        displayImg: 'Carousel',
        url: 'preview-gif',
        tooltip: 'When a user taps "More" from the main Buildbox Shop template page, they can flip through your provided images here.  We require you to include at least one image, and you can upload a maximum of 10. Accepted image formats include .png or .jpg/.jpeg, and .bmp. Individually carousel images cannot exceed 2mb. The orientation of the images needs to match the orientation of your game (landscape or portrait).'
      }
    } else {
      return {
        displayImg: 'GIF',
        url: 'price',
        tooltip: 'You have two options here.  You can upload a standard .png, .jpg/.jpeg, or .bmp to be displayed as the primary image representing your project on the main Buildbox Shop page. Or, you supply an animated .gif or .webp file, and when a user hovers over your template this will automatically animate, showing your template in action. This file cannot exceed 2mb.'
      }
    }
  }

  const reorder = (list: CarouselEntity[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 2;

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
    marginTop: '8%'
  });

  const { displayImg, url, tooltip } = getDispNameAndUrl()

  const nextButtonClicked = () => {
    // Log.info("save the preview/continue")
    if( assetValidateForSaveDraft(currTemplate) ){
      dispatch(saveDraftAuthoredTemplate())
    }
    navigate(`/templates/a/${currTemplate.Id}/${url}`)
  }

  const onSaveDraft = async () => {
    try {
      if(assetValidateForSaveDraft(currTemplate)){
        dispatch(saveDraftAuthoredTemplate())
      }
      // navigate(`/dashboard/a/${currSelectedTemplate.Id}/upload`)
    } catch (err) {
      console.error(err)
    }
  }

  const deleteCarouselImage = async (val: boolean) => {
    
    if (val) {
      setOpenConfirmation(false)
      // setSelectedItem(0);
      await dispatch(deleteCarouselImageAPI(deleteImageBody))
    } else {
      setOpenConfirmation(false)
    }
  }

  const handleCarouselImageChange = (i: number, item: any) => {
    const tempUrl = item.props.children.props.src;
    const currentCarouselImage = currTemplate.AssetStoreItemPageMedia.Carousel.find(image => image.Url === tempUrl) as CarouselEntity;
    
    setDeleteImageBody({
      Id: currTemplate.Id,
      TemplateUrl: tempUrl,
      Order: currentCarouselImage.Order
    })
  }

  const handleOnDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      currTemplate.AssetStoreItemPageMedia.Carousel,
      result.source.index,
      result.destination.index
    );

    items.forEach((item, index) => {
      item.Order = index
    })
    currTemplate.AssetStoreItemPageMedia.Carousel = items;


    dispatch(setCurrentTemplate(currTemplate))
    dispatch(setSaveCurrentTemplate(currTemplate))
    dispatch(saveDraftAuthoredTemplate())
  }

  const handleModalClose = () => {
    setOpenReOrder(false)
  }
  const handleModalOpen = () => {
    setOpenReOrder(true)
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={buttonHeaderStyles.innerButtonContainer}>
          <div>
            <h1>{displayImg}: {currTemplate?.AssetStoreItem?.Name}</h1>
          </div>
          <AssetHeaderButtons type="template" handleSaveClick={onSaveDraft} disabled={!assetValidateForSaveDraft(currTemplate)}/>
        </div>
        <div className={styles.spaceContent}>
          <GenericToolTip label={displayImg} title={
            <div>
              <p>{tooltip}</p>
            </div>
          } />
          {imageCategory === 'carousel' && <div className={styles.customCarousel}>
            {currTemplate?.AssetStoreItemPageMedia?.Carousel?.length > 0 && <Tooltip arrow title="Delete">
            <IconButton>
            <DeleteForeverIcon
                        sx={{
                          cursor: "pointer",
                          '&:hover': {
                            color: "#FF6F44"
                          },
                        }}
                        onClick={() => setOpenConfirmation(true)}
                      />
            </IconButton>
          </Tooltip>}
            {currTemplate?.AssetStoreItemPageMedia?.Carousel?.length > 0 && <Tooltip arrow title="Reorder">
            <IconButton>
            <LoopIcon
                        sx={{
                          cursor: "pointer",
                          '&:hover': {
                            color: "#FF6F44"
                          },
                        }}
                        onClick={() => setOpenReOrder(true)}
                      />
            </IconButton>
          </Tooltip>}
            <Carousel
              showThumbs={false}
              width="40%"
              onChange={(i, item) => handleCarouselImageChange(i, item)}
              selectedItem={selectedItem}
              // dynamicHeight={false}
            >
              {currTemplate?.AssetStoreItemPageMedia?.Carousel?.sort((a, b) => a.Order - b.Order).map(singleUrl => <div key={singleUrl.Url}><img alt="template" src={singleUrl.Url} style={{ width: "100%", height: "230px", objectFit: 'contain' }} /></div>)}
            </Carousel>
          </div>}
          <DropZone imageCategory={imageCategory} item={DropZoneItem.template} />
          <GenericConfirmationModal open={openConfirmation} handleFeedback={deleteCarouselImage} />
          <GenericModal title="Reorder Images" open={openReOrder} handleOpen={handleModalOpen} handleClose={handleModalClose} buttonStyle="filled" buttonStyles={styles.buttonUploadModal} customContainerStyles={modalContainerStyles} useButton={false}>
            <div style={{ display: "flex", alignItems: "flex-end", flexDirection: "row-reverse" }}>
              <IconButton onClick={handleModalClose} size='large'>
                <img src={xIcon} alt='close' />
              </IconButton>
            </div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {currTemplate?.AssetStoreItemPageMedia?.Carousel?.sort((a, b) => a.Order - b.Order).map((singleUrl, index) => (
                      <Draggable key={singleUrl.Url} draggableId={singleUrl.Url} index={index}>
                        {(provided, snapshot) => (
                          <img
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            alt="template"
                            src={singleUrl.Url}
                            style={{
                              width: "40%", objectFit: 'cover', ...getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )
                            }}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </GenericModal>
        </div>

        <div className={styles.buttonGroup}>
          <GenericButton
            onClick={nextButtonClicked}
            variant="filled"
            text="Submit"
            buttonStyles={styles.nextButton}>
            Next
          </GenericButton>
          <SubmitButton type="template" />
        </div>
      </div>
    </div>
  )
}

export default UploadImages;