import {
  removeTemplateFromList,
  setCurrentTemplate,
  setSaveCurrentTemplate,
  setTemplateDownloadLink,
  setTemplateStatus,
  setUserTemplates,
} from 'redux/template/templateActions';
import { DefaultThunkAction } from 'redux/shared';
import { handleLoading } from 'redux/ui/uiThunks';
import { push } from 'connected-react-router';
import { setImageUploading, setMessage, setTemplateProgress, setTemplateSubmitSuccess, setTemplateUploadLoading } from 'redux/ui/uiActions';
import { validateToken } from 'redux/auth/authThunks';
import { getPresignedTemplateData, uploadTemplateFile, uploadATemplate, fetchUserTemplates, NewTemplate, createNewTemplate, saveCurrentTemplate, deleteTemplateAPI, TemplateResourceType, uploadTemplateFileResources, submitTemplateDraft, fetchdownloadTemplate, DeleteCarouselImageBody, deleteTemplateCarouselImage, fetchUserAuthoredTemplate, saveTemplatePriceWhenLive } from 'api/templates';
import { FemaleSharp } from '@mui/icons-material';

type ITemplate = mbb.model.ITemplate;

export function getAllAuthoredTemplates(): DefaultThunkAction<Promise<any>> {
  return async (dispatch, getState) => {
    dispatch(handleLoading(true));
      const accessToken = await dispatch<Promise<string>>(validateToken());
    try {
      const templateList = await fetchUserTemplates(accessToken);
      

      dispatch(setUserTemplates(templateList));
      dispatch(handleLoading(false));
      return templateList;
    } catch (error) {
      // Log.error(error, "Error dispatching: validateToken");
      dispatch(handleLoading(false));

      throw error;
    }
  };
}

export function initCreateAuthoredTemplate(
  data: any
): DefaultThunkAction<Promise<any>> {
  return async (dispatch, getState) => {
    dispatch(handleLoading(true));
    try {
      const { templates } = getState();

      let body: NewTemplate = {
        Name: data.templateName,
        orientation: data.templateType,
        description: data?.templateDescription,
      };

      const accessToken = await dispatch<Promise<string>>(validateToken());
      const res = await createNewTemplate(accessToken, body)

      let newTemplatesObj: any = {
        ...templates.templates,
        [res.Id]: res,
      };

      await dispatch(setUserTemplates(newTemplatesObj));
      await dispatch(setCurrentTemplate(res))
      await dispatch(handleLoading(false));

      dispatch(push(`/templates/a/${res.Id}/details`));

      return res.Id;
    } catch (error) {
      dispatch(handleLoading(false));
      dispatch(setMessage({ message: `${error}`, severity: 'error' }));
    }
  };
}

export function setSelectedCurrentTemplate(
  templateVersionId: string
): DefaultThunkAction<Promise<any>> {
  return async (dispatch, getState) => {
    dispatch(handleLoading(true));
    const { templates } = getState();

    let currTemplate = templates.templates[templateVersionId]; 
    try {
      if (currTemplate === undefined) {
        //TODO: Maybe store items?
      }
      if (templates.currentTemplate != null) {
        if (currTemplate.Id !== templates.currentTemplate?.Id) {
          // prompt user if they want to save currentTemplate before making the switch to the new currTemplate
        }
      }

      await dispatch(setTemplateSubmitSuccess(false));
      await dispatch(setCurrentTemplate(currTemplate));
      dispatch(handleLoading(false));
      return currTemplate;
    } catch (err) {
      dispatch(handleLoading(false));
    }
  };
}

export function updateCurrentSelectedTemplate(
  updatedTemplate: ITemplate,
  saveDraft: boolean = true,
): DefaultThunkAction<Promise<any>> {
  return async (dispatch, getState) => {
    if (saveDraft) {
      dispatch(handleLoading(true));
    }
    try {
      //TODO: Maybe had an edited item
      await dispatch(setCurrentTemplate(updatedTemplate));
      await dispatch(setSaveCurrentTemplate(updatedTemplate))
      if (saveDraft) {
        await dispatch(saveDraftAuthoredTemplate());
      }
      await dispatch(handleLoading(false));
      return [];
    } catch (error) {
      // Log.error(error, "Error dispatching: validateToken");
      dispatch(handleLoading(false));

      throw error;
    }
  };
}

export function saveDraftAuthoredTemplate(): DefaultThunkAction<Promise<any>> {
  return async (dispatch, getState) => {
    dispatch(handleLoading(true));
    try {
      const accessToken = await dispatch<Promise<string>>(validateToken());
      const { templates } = getState();
      const currentTemplate = templates.currentTemplate;
      
      const result = await saveCurrentTemplate(accessToken, currentTemplate)
      await dispatch(setSaveCurrentTemplate(result.template));
      await dispatch(setCurrentTemplate(result.template))
      dispatch(handleLoading(false));
      return;
    } catch (error) {
      // Log.error(error, "Error dispatching: validateToken");
      dispatch(handleLoading(false));

      throw error;
    }
  };
}
  
  export function downloadTemplate(templateVersionId: string): DefaultThunkAction<Promise<any>> {
    return async (dispatch, getState) => {
      await dispatch(handleLoading(true))
      try {
        const { templates } = getState();
        const currentTemplate:ITemplate = templates.currentTemplate;
        
        const accessToken = await dispatch<Promise<string>>(validateToken());
        const url = await fetchdownloadTemplate(accessToken, templateVersionId);
        
  
        await dispatch(setTemplateDownloadLink(url.url))

        await dispatch(handleLoading(false))
      } 
      catch (error: any) {
        await dispatch(handleLoading(false))
        dispatch(setMessage({ message: `There was an error: ${error}`, severity: 'error'}))
      }
    }
  
  }

  export function uploadFileTemplate(file: File): DefaultThunkAction<Promise<any>> { 
    return async (dispatch, getState) => {
      // await dispatch(setTemplateProgress(0))
      await dispatch(setTemplateUploadLoading(true))
      const accessToken = await dispatch<Promise<string>>(validateToken());
      try {
        const { templates } = getState();
        const currentTemplate = templates.currentTemplate;
  
        if(!file.name.endsWith(".bbdoc")){
          await dispatch(setMessage({message: 'Incorrect file type', severity: 'error'}))
          await dispatch(setTemplateUploadLoading(false))
          return
        }
        
        if(file.size > 1048576 * 2000){
          dispatch(setMessage({ message: `File is larger than the 2GB limit`, severity: 'error'}))
          await dispatch(setTemplateUploadLoading(false))
          return
        }
  
        const presignedPostData = await getPresignedTemplateData( accessToken, currentTemplate);
  
        const completedS3Upload = await uploadTemplateFile(presignedPostData, file);

        // use presign url to create the connection to send the data to s3 bucket.
  
        const tempTemplate = await uploadATemplate(accessToken, currentTemplate)

        
        await dispatch(setSaveCurrentTemplate(tempTemplate.result))
        await dispatch(setCurrentTemplate(tempTemplate.result))
        await dispatch(setTemplateUploadLoading(false))
      } 
      catch (error: any) {
        await dispatch(setTemplateUploadLoading(false))
        await dispatch(setTemplateProgress(0))
        dispatch(setMessage({ message: `There was an error: ${error}`, severity: 'error'}))
       
      }
    }
  }

  export function deleteTemplate( template: ITemplate): DefaultThunkAction<Promise<any>> { 
    return async (dispatch, getState) => {
      await dispatch(handleLoading(true))
      try {
        const { templates } = getState()
        const accessToken = await dispatch<Promise<string>>(validateToken());
        await deleteTemplateAPI( accessToken, {Id: template.Id})
        
        const temp = {
          ...templates.templates
        }
        const newTemp = {} as Record<string, any>
  
        Object.keys(temp).forEach(key => {
          if(key !== template.Id){
            newTemp[key] = temp[key]
          }
        })
        
        await dispatch(removeTemplateFromList(newTemp))
        dispatch(setMessage({message: `Template: ${template.AssetStoreItem.Name } was deleted successfully`, severity: 'success'}))
        dispatch(handleLoading(false))
        return []
      } catch (error) {
        dispatch(setMessage({ message: `There was an error: ${error}`, severity: 'error'}))
        dispatch(handleLoading(false))
        throw error;
      }
    }
  }

  export function uploadTemplateResource(files: any[], resourceType: TemplateResourceType): DefaultThunkAction<Promise<any>> { 
    return async (dispatch, getState) => {
      // await dispatch(handleLoading(true))
      await dispatch(setImageUploading(true))
      const accessToken = await dispatch<Promise<string>>(validateToken());
      try {
        const { templates } = getState();
        const currentTemplate:ITemplate = templates.currentTemplate;
        
        let result = null;
        if((files.length > 10 || files.length + currentTemplate.AssetStoreItemPageMedia.Carousel.length > 10) && resourceType === 'CAROUSEL_IMAGE'){
          throw Error('You can only upload 10 images at a time or the number of files you are uploading is going to excreed the 10 image limit')
        }

        let newArrayOfImages = [] as {Order: number, Url: string}[]
        
        if( resourceType === 'CAROUSEL_IMAGE' ){
          result =  await Promise.all( files.map(async (file: any, index) => { 
            const currentValue:string = (index + currentTemplate.AssetStoreItemPageMedia.Carousel.length).toString();
            const item = await uploadTemplateFileResources(accessToken, file, currentTemplate, resourceType,currentValue);
            const size = item.AssetStoreItemPageMedia.Carousel.length;
            newArrayOfImages.push (item.AssetStoreItemPageMedia.Carousel[size - 1]) 
            return item
          }))

          currentTemplate.AssetStoreItemPageMedia.Carousel = [...currentTemplate.AssetStoreItemPageMedia.Carousel, ...newArrayOfImages]
        } else {
          result = await uploadTemplateFileResources(accessToken, files[0], currentTemplate, resourceType);
          
          currentTemplate.AssetStoreItemPageMedia.PreviewGif = (result as ITemplate).AssetStoreItemPageMedia.PreviewGif
        }

        await dispatch(setSaveCurrentTemplate(currentTemplate))
        await dispatch(setCurrentTemplate(currentTemplate))
  
        dispatch(setMessage({message: `Template ${resourceType === 'PREVIEW_GIF' ? 'Preview Gif' : 'Carousel Image'} was uploaded successfully`, severity: 'success'}))
        await dispatch(setImageUploading(false))
        return result
      } 
      catch (error: any) {
        await dispatch(setImageUploading(false))
        dispatch(setMessage({ message: `There was an error: ${error}`, severity: 'error'}))
      }
    }
  }

  export function sumbitTemplateForReview(): DefaultThunkAction<Promise<any>> {
    return async (dispatch, getState) => {
      await dispatch(handleLoading(true))
      const accessToken = await dispatch<Promise<string>>(validateToken());
      try {
        const { templates } = getState();
        const currentTemplate:ITemplate = templates.currentTemplate;
        
        await saveCurrentTemplate(accessToken, currentTemplate )
        const result = await submitTemplateDraft(accessToken,currentTemplate)
        
        currentTemplate.Status = 'SUBMITTED'
 
        await dispatch(setTemplateStatus(currentTemplate))
        await dispatch(setSaveCurrentTemplate(currentTemplate))
        await dispatch(setTemplateSubmitSuccess(true))
        await dispatch(setCurrentTemplate(currentTemplate))
  
        await dispatch(handleLoading(false))
        return result
      } 
      catch (error: any) {
        await dispatch(handleLoading(false))
        dispatch(setMessage({ message: `There was an error: ${error}`, severity: 'error'}))
      }
    }
  }

export function deleteCarouselImage(body: DeleteCarouselImageBody): DefaultThunkAction<Promise<any>> {
  return async (dispatch, getState) => {
    await dispatch(handleLoading(true))
    const accessToken = await dispatch<Promise<string>>(validateToken());

    try {
      const { templates } = getState();
      const currentTemplate:ITemplate = templates.currentTemplate;
      
      const result = await deleteTemplateCarouselImage(accessToken, body)

      await dispatch(setCurrentTemplate(result.result))
      await dispatch(setSaveCurrentTemplate(result.result))

      return result
    } 
    catch (error: any) {
      await dispatch(handleLoading(false))
      dispatch(setMessage({ message: `There was an error: ${error}`, severity: 'error'}))
    }
  }
}

export function updateCurrentSelectedTemplatePrice(
  updatedTemplate: ITemplate,
  saveDraft: boolean = true,
): DefaultThunkAction<Promise<any>> {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      await dispatch(setCurrentTemplate(updatedTemplate));
      await dispatch(setSaveCurrentTemplate(updatedTemplate))
      if (saveDraft) {
        await dispatch(saveAuthoredTemplatePrice(updatedTemplate));
      }
      await dispatch(handleLoading(false));
      return [];
    } catch (error) {
      // Log.error(error, "Error dispatching: validateToken");
      dispatch(handleLoading(false));

      throw error;
    }
  };
}

export function saveAuthoredTemplatePrice(updatedTemplate: ITemplate): DefaultThunkAction<Promise<any>> {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const accessToken = await dispatch<Promise<string>>(validateToken());
      const result = await saveTemplatePriceWhenLive(accessToken, updatedTemplate)
      await dispatch(setSaveCurrentTemplate(result));
      await dispatch(setCurrentTemplate(result))
      dispatch(handleLoading(false));
      return;
    } catch (error) {
      // Log.error(error, "Error dispatching: validateToken");
      dispatch(handleLoading(false));

      throw error;
    }
  };
}
