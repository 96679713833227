type AssetItem = mbb.model.IAsset

export const currentMyAuthoredAssets = (state: { assets: { assets: Record<string, AssetItem> } }) => {
  return state.assets.assets;
};

// FIXME: createnewAssetDTO instead of any?
export const currentSelectedAsset = ( state: { assets: { currentAsset: AssetItem }}) => {
  return state.assets.currentAsset;
}

export const currentUnSavedAssetEdits = ( state: { assets: { currentAsset: AssetItem }}) => {
  return state.assets.currentAsset;
}

export const getUploadAssetLoaded = (state: { assets: { currentAsset: any }}) => {
  return state.assets.currentAsset.url;
}

export const currentScreenShotImage = (state: { assets: { currentAsset: AssetItem}}) => {
  return state.assets?.currentAsset?.AssetStoreItemPageMedia?.Screenshot1Url;
}

export const currentThumbNailImage = (state: { assets: { currentAsset: AssetItem}}) => {
  return state.assets.currentAsset.AssetStoreItemPageMedia.ThumbnailMediaUrl;
}

export const setAssetDownloadLink = ( state: { assets: { AssetDownloadLink: string }}) => {
  return state.assets.AssetDownloadLink;
}