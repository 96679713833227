import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { History } from 'history';

import auth from "../auth/authReducer";
import ui from '../ui/uiReducer';
import assets from "../asset/assetReducer";
import earnings from '../earnings/earningsReducer';
import templates from "../template/templateReducer"

export default function createRootReducer(history: History) {
  return combineReducers({
    templates,
    assets: assets,
    auth: auth,
    ui: ui,
    earnings: earnings,
    router: connectRouter(history),
  });
}
