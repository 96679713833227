type TemplateItem = mbb.model.ITemplate

export const currentMyAuthoredTemplates = (state: { templates: { templates: Record<string, TemplateItem> } }) => {
  return state.templates.templates;
};

export const currentSelectedTemplate = ( state: { templates: { currentTemplate: TemplateItem }}) => {
  return state.templates.currentTemplate;
}

export const setTemplateDownloadLink = ( state: { templates: { TemplateDownloadLink: string }}) => {
  return state.templates?.TemplateDownloadLink;
}

export const currentTemplateCarousel = (state: { templates: { currentTemplate: TemplateItem}}) => {
  return state.templates?.currentTemplate?.AssetStoreItemPageMedia?.Carousel;
}

export const currentTemplatePreviewGif = (state: { templates: { currentTemplate: TemplateItem}}) => {
  return state.templates?.currentTemplate?.AssetStoreItemPageMedia?.PreviewGif;
}

export const getUploadTemplateLoaded = (state: { templates: { currentTemplate: TemplateItem }}) => {
  return state.templates?.currentTemplate?.TemplateUrl;
}
