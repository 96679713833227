export function formatUNIXTimestamp(timestamp: any, hyperWalletStyle: any): any {
  if (timestamp) {
    const dateObject = new Date(timestamp);
    let month = dateObject.toLocaleString("en-US", { month: "short" });
    let day = dateObject.toLocaleString("en-US", { day: "numeric" });
    let year = dateObject.toLocaleString("en-US", { year: "numeric" });
    if (hyperWalletStyle) {
      month = dateObject.toLocaleString("en-US", { month: "2-digit" });
      day = dateObject.toLocaleString("en-US", { day: "2-digit" });
      year = dateObject.toLocaleString("en-US", { year: "numeric" });

      return `${year}-${month}-${day}`;
    }
    return `${month} ${day}, ${year}`;
  }
  return "";
}

export function centsToDollarString(cents:number): string {
  if (cents >= 100) {
    const str = cents + ""
    const decimalIndex = str.length - 2
    return `$${str.substring(0, decimalIndex)}.${str.substring(decimalIndex)}`
  }
  else if (cents >= 10) {
    return `$0.${cents + ""}`
  }
  else {
    return `$0.0${cents + ""}`
  }
}

export const downloadFile = (fileName:string) => {
  const a = document.createElement('a')
  a.download = fileName
  a.href = fileName
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}


export const throttle = (fn: Function, wait: number = 300) => {
  let inThrottle: boolean,
    lastFn: ReturnType<typeof setTimeout>,
    lastTime: number;
  return function (this: any) {
    const context = this,
      args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};
