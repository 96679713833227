import * as React from 'react'
import Button from '@mui/material/Button'
import styles from 'styles//_buttons.module.scss'

// FIXME: Dleete this component maybe?
export interface IButtonProps {
  text?: string
  variant: string
  onClick: Function
  buttonStyles?: any
  icon?: any
  type?: any
  disabled?: boolean
  children: React.ReactNode
}

const GenericButton: React.FC<IButtonProps> = ({ children, ...props }) => {
  const {
    text,
    variant = 'default',
    onClick,
    buttonStyles,
    icon,
    type,
    disabled = false,
  } = props

  const Buttons = () => {
    switch (variant) {
      case 'outlined':
        return (
          <Button
            variant='outlined'
            color='secondary'
            onClick={(e) => onClick(e)}
            startIcon={icon && <img src={icon} />}
            className={buttonStyles ? buttonStyles : styles.buttonFilled}
            type={type}
            disabled={disabled}
          >
            {children}
          </Button>
        )
      case 'filled':
        return (
          <Button
            variant='contained'
            className={buttonStyles ? buttonStyles : styles.buttonFilled}
            onClick={(e) => onClick(e)}
            startIcon={icon && <img src={icon} />}
            type={type}
            disabled={disabled}
          >
            {children}
          </Button>
        )
      default:
        return (
          <Button
            className={buttonStyles ? buttonStyles : styles.buttonFilled}
            startIcon={<img src={icon} />}
            onClick={(e) => onClick(e)}
            type={type}
            disabled={disabled}
          >
            {children}
          </Button>
        )
    }
  }

  return (
    <>
      <Buttons />
    </>
  )
}

export default GenericButton
