import { Modal, Box, Avatar, FormHelperText } from "@mui/material";
import { FunctionComponent } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";
import GenericButton from "../Buttons/GenericButton";
import { CssTextField } from "../Inputs/GenericInputLabel";
import styles from 'styles/AssetDeleteModal.module.scss';
import ClearIcon from '@mui/icons-material/Clear';

interface GenericConfirmationModalProps {
    open: boolean;
    handleFeedback: (val: boolean) => void
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#F1F1F1',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "6px",
    width: "560px",
    padding: "0px"
};

type Inputs = {
    confirmDelete: string
}

const GenericConfirmationModal: FunctionComponent<GenericConfirmationModalProps> = ({open, handleFeedback}) => {
    const dispatch = useDispatch()
    const {
        // reset,
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>()

    const watchConfirmMsg = watch("confirmDelete", "");
    // const deleteMsg = `delete ${props.asset.AssetStoreItem.Name}`;
    const deleteMsg = `delete`;


    const onSubmit: SubmitHandler<Inputs> = (data) => {
        if (data.confirmDelete === deleteMsg) {
            handleFeedback(true);
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleFeedback}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box sx={style}>
                <div className={styles.container}>
                    <h2 className={styles.thankYou}>
                        Are You Sure?
                    </h2>
                    <Avatar style={{ borderColor: "red", height: 90, width: 90, background: "transparent" }}>
                        <ClearIcon style={{ color: "red", height: 90, width: 90 }} />
                    </Avatar>
                    {/* <img src={ClearIcon} alt="checkmark" className={styles.checkMark} /> */}
                    <div className={styles.container}></div>
                    <div className={styles.message}>
                        <p className={styles.text}>This action cannot be undone, </p>
                        <p className={styles.text}>type <i>delete</i> and click Delete.</p>
                    </div>
                    {/* <Typography id='modal-modal-description' sx={{ mt: 2 }}>
          This action cannot be undone, type '<i>confirm delete</i>' and click Delete.
        </Typography> */}
                    <div>
                        <CssTextField
                            // label="Coni"
                            color='primary'
                            type='assetVersion'
                            placeholder='delete'
                            // disabled
                            size='small'
                            // defaultValue={selectedAsset.Version}
                            // helperText={ errors.confirmDelete ? <span style={{color: "red"}}>This field is required Asset Version</span> : ""}
                            {...register("confirmDelete")}
                        // className={styles.inputStyle}
                        />
                        {errors.confirmDelete ? (
                            <FormHelperText
                                id='component-helper-text'
                                sx={{ color: "red", marginLeft: "40px", marginTop: "-11px", marginBottom: "20px" }}
                                error={errors.confirmDelete ? true : false}
                            >
                                This field is required 
                            </FormHelperText>
                        ) : (
                            " "
                        )}
                    </div>
                    <div style={{ marginTop: "20px", marginBottom: "30px" }}>
                        <GenericButton buttonStyles={styles.outlinedButton} variant="outlined" onClick={() => handleFeedback(false)}>Cancel</GenericButton>
                        <GenericButton buttonStyles={watchConfirmMsg !== deleteMsg ? styles.deleteAssetButtonDisabled : styles.deleteAssetButton} variant="outlined" disabled={watchConfirmMsg !== deleteMsg} onClick={handleSubmit(onSubmit)} >
                            DELETE
                        </GenericButton>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default GenericConfirmationModal;