import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Button from '@mui/material/Button'
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import GenericButton from 'common/components/Buttons/GenericButton'
import { useSelector } from 'react-redux';
import { getLoadingState } from 'redux/ui/uiSelectors'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F1F1F1',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: "6px",
  width: "560px",
  padding: "0px",
}

export interface IModalProps {
  title: string
  open: boolean
  handleOpen(): any
  handleClose(): any
  buttonStyle: string
  buttonStyles: any,
  customContainerStyles: Record<string, string | number>,
  useButton: boolean
}

const GenericModal: React.FC<IModalProps> = ({ children, ...props }) => {

  const { 
    title, 
    open, 
    handleClose, // may need to bring this back?
    handleOpen, 
    buttonStyle,
    buttonStyles,
    customContainerStyles,
    useButton } = props

  return (
    <div style={{width: "100%"}}>
  
      {useButton && <GenericButton variant={buttonStyle}  onClick={handleOpen} buttonStyles={buttonStyles}>
        {title}
      </GenericButton>}
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={{...style, ...customContainerStyles}}>{children}</Box>
        </Fade>
      </Modal>
    </div>
  )
}

export default GenericModal
