import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import { isMobile } from 'react-device-detect';
import DrawerMobile from './DrawerMobile';
import DrawerDesktop from './DrawerDesktop';

const NavMenu = () => {
  const matches = useMediaQuery('(min-width:800px)');
  
  return (
    <div>
      { matches && !isMobile ? 
        <DrawerDesktop/> :
        <DrawerMobile/>
      }
    </div>
  )
}

export default NavMenu
