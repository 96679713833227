import { AnyAction } from 'redux'
import actions from '../actionTypes.json'

const initialState: mbb.state.IAuth = {
  user: null,
  gaClientId: null,
  fromBBClient: false,
  cognitoClientId: null,
  clientSecret: null,
  authTokens: null,
  screenName: "",
  username: null,
}

const authReducer = (
  state: mbb.state.IAuth = initialState,
  action: AnyAction
): mbb.state.IAuth => {
  switch (action.type) {
    case actions.auth.SET_USER:
      return {
        ...state,
        user: action.user,
      }
    case actions.auth.SET_SCREEN_NAME:
      return {
        ...state,
        screenName: action.screenNameString,
      }
    case actions.auth.SET_USERNAME:
      return {
        ...state,
        username: action.username
      }
    case actions.auth.SET_GA_CLIENT_ID:
      return {
        ...state,
        gaClientId: action.gaClientId,
      }
    case actions.auth.SET_AUTH_TOKENS:
      return {
        ...state,
        authTokens: action.tokenObject,
      }
    case actions.auth.SET_COGNITO_CLIENT_INFO:
      return {
        ...state,
        cognitoClientId: action.cognitoClientId,
        clientSecret: action.clientSecret,
      }
    case actions.auth.CLEAR_USER:
      return initialState
    default:
      return state
  }
}

export default authReducer