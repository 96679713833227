import React from 'react'
import { InputLabel, TextField, Select } from '@mui/material'
import { alpha, styled } from '@mui/material/styles';

interface IInputLabelProps {
    htmlFor?: string
    color?: string
    inputLabelStyles?: any
    children: React.ReactNode
}

const GenericInputLabel: React.FC<IInputLabelProps> = ({ children, ...props}) => {
    const {
        htmlFor, 
        inputLabelStyles,
    } = props

    return (
      <InputLabel
        htmlFor={htmlFor}
        className={inputLabelStyles}
      >
        {children}
      </InputLabel>
    )
}

export const CssTextField = styled(TextField)({
    borderRadius: "4px !important", 
    marginLeft: "0", 
    // background: "white",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFBD00',
    },
    '& .MuiOutlinedInput-root': {
       borderRadius: "4px !important",
       background: "white",
      '& label.Mui': {
        color: 'black',
      },
      '& fieldset': {
        borderColor: 'transparent',
         borderRadius: "4px !important",
        backgroundColor: 'rgb(171, 171, 171, .1)',
      },
      '&:hover fieldset': {
        borderColor: '#FFBD00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FFBD00',
      },
    },
  });

export const GenericSelect = styled(Select)({
  borderRadius: "4px !important", 
  marginLeft: "0", 
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiSelect-underline:after': {
    borderBottomColor: '#FFBD00',
  },
  '& .MuiOutlinedSelect-root': {
     borderRadius: "4px !important",
    '& label.Mui': {
      color: 'black',
    },
    '& fieldset': {
      borderColor: 'transparent',
       borderRadius: "4px !important",
      backgroundColor: 'rgb(171, 171, 171, .1)',
    },
    '&:hover fieldset': {
      borderColor: '#FFBD00',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FFBD00',
    },
  },
});


export default GenericInputLabel