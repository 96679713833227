//------------------------------------------------------------------------------
// Imports Section 
//------------------------------------------------------------------------------
import { createTheme } from '@mui/material/styles';


//------------------------------------------------------------------------------
// Theme Section
//------------------------------------------------------------------------------
const theme = createTheme({
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingTop: '12px',
                    paddingBottom: '12px'
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: '40px',

                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: '40px !important',
                    padding: '0px !important',

                }
            }
        }
    }
})


//------------------------------------------------------------------------------
// Exports Section
//------------------------------------------------------------------------------
export default theme;