import actions from '../actionTypes.json'
import { AnyAction } from 'redux'
import { LoginClientType } from 'redux/models'

export function setUser(user: any): AnyAction {
  return {
    type: actions.auth.SET_USER,
    user,
  }
}

export function setAuthTokens(tokenObject: any): AnyAction {
  return {
    type: actions.auth.SET_AUTH_TOKENS,
    tokenObject,
  }
}

export function setUserScreenName(screenName: string): AnyAction {
  const screenNameString = null === screenName ? "" : screenName
  return {
    type: actions.auth.SET_SCREEN_NAME,
    screenNameString,
  }
}

export function setCognitoClientInfo(
  cognitoClientId: string,
  clientSecret: string
) {
  return {
    type: actions.auth.SET_COGNITO_CLIENT_INFO,
    cognitoClientId,
    clientSecret,
  }
}

export function clearUser(): AnyAction {
  return {
    type: actions.auth.CLEAR_USER,
  };
}

export function setUsername(username: string): AnyAction {
  return {
    type: 'SET_USERNAME',
    username,
  }
}

export function setLoggingInfromClient(client: LoginClientType): AnyAction {
  return {
    type: actions.auth.SET_LOGIN_TYPE,
    client,
  };
}

