import actions from '../actionTypes.json';
import { AnyAction } from 'redux'

type ITemplate = mbb.model.ITemplate

export function setUserTemplates(templates: Record<string,ITemplate> ): AnyAction {
  return {
    type: actions.templates.SET_TEMPLATES,
    templates,
  }
}

export function setCurrentTemplate(template: ITemplate): AnyAction { 
  return {
    type: actions.templates.SET_CURRENT_TEMPLATE,
    template
  }
}

export function setSaveCurrentTemplate( template: ITemplate ): AnyAction {
  return {
    type: actions.templates.SAVE_TEMPLATE,
    template
  }
}

export function updateTemplateUrl(url: string): AnyAction {
  return {
    type: actions.templates.UPDATE_TEMPLATE_URL,
    url
  }
}

export function setTemplateScreenShotUrl( url: string ): AnyAction {
  return {
    type: actions.templates.SET_TEMPLATE_SCREENSHOT_URL,
    url
  }
}

export function removeTemplateFromList(templates: any): AnyAction{
  return {
    type: actions.templates.REMOVE_TEMPLATE,
    templates
  }
}

export function setTemplateStatus(template: ITemplate): AnyAction {
  return {
    type: actions.templates.SET_TEMPLATE_STATUS,
    template
  }
}

export function setTemplateDownloadLink(url: string):AnyAction {
  return {
    type: actions.templates.SET_TEMPLATE_DOWNLOAD_LINK,
    url
  }
}

export function clearTemplates(): AnyAction {
  return {
    type: actions.templates.CLEAR_TEMPLATES
  }
}