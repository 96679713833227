import React, { 
  useState,
  useEffect 
} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useSelector } from "react-redux";
import {getLoadingState, getNavDrawerState}  from 'redux/ui/uiSelectors';
import { drawerWidth } from '../constants/menuConstants';
import useUserIsLoggedIn from '../hooks/useUserIsLoggedIn';
import styles from 'styles/PageContainer.module.scss';
import { useLocation } from "react-router-dom";
import BackDropLoading from 'common/components/Loading/BackDropLoading';

interface ParentProps {
  childComp?: React.ReactNode;
}

//-----------------------------------------------------------------------------------------------------
const Main = styled('main', { shouldForwardProp: (prop) =>  prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  }),
}));


//-----------------------------------------------------------------------------------------------------
const PageContainer: React.FC<ParentProps> = (props) => {
  const { childComp } = props;
  const navDrawerOpen = useSelector(getNavDrawerState);
  const loading = useSelector(getLoadingState);
  // const userIsloggedIn = useUserIsLoggedIn();

  return (
    <Box sx={{ display: 'flex', backgroundColor: "#e3e3e3"}} className={styles.body} >
      <Main open={navDrawerOpen} >
        { loading && <BackDropLoading open={loading}/>}
        <div className={styles.content}>
          {childComp}
        </div>
      </Main>

    </Box>
  )
}

//-----------------------------------------------------------------------------------------------------
export const TabContainer: React.FC<ParentProps> = (props) => {
  const { childComp } = props;
  const navDrawerOpen = useSelector(getNavDrawerState);
  const userIsloggedIn = useUserIsLoggedIn();
  const assetVersionId = useLocation().pathname.split('/')[3];
  
  return (
    <Main open={navDrawerOpen} >
      <div className={styles.content}>
        {childComp}
      </div>
    </Main>
  )
}
export default PageContainer
