import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import styles from 'styles/Navbar.module.scss';
import Drawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getNavDrawerState } from 'redux/ui/uiSelectors';
import { switchNavDrawer } from '../../../redux/ui/uiThunks';
import { drawerWidth, navItems } from 'common/constants/menuConstants';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const PersistentDrawerLeft = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navDrawerOpen = useSelector(getNavDrawerState);

  const handleDrawerOpen = () => {
    dispatch(switchNavDrawer(true))
  };

  const handleDrawerClose = () => {
    dispatch(switchNavDrawer(false))
  };

  return (
    <div>
      <AppBar position="fixed" open={navDrawerOpen} className={styles.navbar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(navDrawerOpen && { display: 'none' }) }}
            className={styles.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            borderRight: '1px solid black'
          },
        }}
        variant="persistent"
        anchor="left"
        open={navDrawerOpen}
        className={styles.drawer}
      >
         <div className={styles.drawerHeader}>
          <Typography variant="h5"  >
            Market Place
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List className={styles.navLinks}>
          {navItems.map((item, index) => (
            <NavLink key={index} to={item.path} className={({ isActive }) =>
            isActive ? styles.active: ''
             }>
              <ListItem key={item.title} className={styles.links}>
                <ListItemText primary={item.title} />
              </ListItem>
            </NavLink>
          ))}
        </List>
        <Divider />
      </Drawer>
    </div>
  );
}

export default PersistentDrawerLeft