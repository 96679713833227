import React, { useState } from 'react'
import GenericButton from 'common/components/Buttons/GenericButton';
import arrowLeftIcon from 'assets/images/10x10/Arrow Left.svg';
import styles from 'styles/SelectedAssetPage.module.scss';
import stylesTwo from 'styles/SelectedTemplatePage.module.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { saveDraftAuthoredAsset, sumbitMyAsset} from 'redux/asset/assetThunks'
import { submitAssetDraft } from 'api/assets';
import { currentSelectedAsset } from 'redux/asset/assetSelectors';


const AssetHeaderButtons = (props:any) => {
  const navigate = useNavigate();
  const dipatch =  useDispatch();


  const handleToDashboard = () =>{
    navigate("/dashboard");
  }
  const handleSaveDraft = () => {
    if(props.handleSaveClick){
      props.handleSaveClick()
    }
    else{
      dipatch(saveDraftAuthoredAsset());
    }
  }

  // const handleSubmit = () => {
   
  //   dipatch(sumbitMyAsset());
  // }

  return (
    <GenericButton variant="outlined" onClick={handleSaveDraft} buttonStyles={props.type ? stylesTwo.baseHeaderButton : styles.baseHeaderButton} disabled={props.disabled}>
    Save Draft  
  </GenericButton>
    // <div className={styles.headerButtonContainer}>
    //   <div className={styles.innerButtonContainer}>
    //     {/* <GenericButton icon={arrowLeftIcon} variant="outlined" onClick={handleToDashboard} buttonStyles={styles.baseHeaderButton}>
    //       To Dashboard  
    //     </GenericButton> */}
    //     <GenericButton variant="outlined" onClick={handleSaveDraft} buttonStyles={styles.baseHeaderButton}>
    //       Save Draft  
    //     </GenericButton>
    //   </div>
    // </div>
  )
}

export default AssetHeaderButtons
