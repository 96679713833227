class ValidateForms { 

  isValidAssetInitialCreate(form: any) {
    //Form must contain at least a 
    // Name, Version, and everything else will be optional
       
    return false;
  }

  
}

export default new ValidateForms()

//double check these types homieß
export const validateText = (value: string) => {
  if (value === '') {
    return false
  }
  return true
}

export const validateEmail = (email: any) => {
  const emailRegex = RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
  return emailRegex.test(email)
}

//may not need dis?
export const validateUSPhoneNumber = (phoneNumber: any) => {
  const phoneNumberRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  return phoneNumberRegex.test(phoneNumber)
}

export const assetValidateForSaveDraft = (asset: mbb.model.ITemplate |  mbb.model.IAsset): boolean => {
  if( asset?.Status === "REMOVED_FROM_SALE" || 
      asset?.Status === "NOT_READY" ||
      asset?.Status === "REJECTED") {
    return true
  }

  return false
}