import { FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import SubmitButton from "common/components/Buttons/SubmitButton";
import { CssTextField } from "common/components/Inputs/GenericInputLabel";
import { PriceToolTip } from "common/components/ToolTip/GenericToolTip";
import AssetHeaderButtons from "pages/DashboardPage/components/AssetHeaderButtons";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { currentSelectedTemplate } from "redux/template/templateSelectors";
import Log from "utils/Log";
import styles from 'styles/TemplatePrice.module.scss';
import {  updateCurrentSelectedTemplatePrice } from "redux/template/templateThunks";
import buttonHeaderStyles from 'styles/SelectedTemplatePage.module.scss';
import { throttle } from "utils/HelperUtils";

interface TemplatePriceProps {
    
}

type Inputs = {
  templatePrice: number
}


 
const TemplatePrice: FunctionComponent<TemplatePriceProps> = () => {
  const dispatch = useDispatch();
  const currTemplate = useSelector(currentSelectedTemplate);
  const [ priceOption, setPriceOption ] = useState<string>("free");
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm<Inputs>()

  const [disabledField, setdisabledField] = useState<boolean>(false);

  const test = watch("templatePrice", currTemplate?.CostInVC)

  useEffect(() => {
    if(currTemplate?.CostInVC === 0){
      setPriceOption("free")
    }
    else {
      setPriceOption("price")
    }
  },[currTemplate])


  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
     await setPriceOption((event.target as HTMLInputElement).value);
     getValuesFromInputs((event.target as HTMLInputElement).value)
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {

    const updateCurrentTemplate = {
      ...currTemplate,
    }
    
    //TODO: have this call the correct price change
    switch (priceOption){
      case "free":
        Log.info("free")
        updateCurrentTemplate.CostInVC = 0;
        updatePrice(updateCurrentTemplate)
        break;
      case "price":
        Log.info("price inputed")
        if(data.templatePrice === 0){
          updateCurrentTemplate.CostInVC = 0;
        }
        else{
          updateCurrentTemplate.CostInVC = data.templatePrice;
        }
        updatePrice(updateCurrentTemplate)
        break;
      default:
       break;
    }
  }

  const onSaveClick: SubmitHandler<Inputs> = async (data) => {

    const updateCurrentTemplate = {
      ...currTemplate,
    }
    
    switch (priceOption){
      case "free":
        Log.info("free")
        updateCurrentTemplate.CostInVC = 0;
        updatePrice(updateCurrentTemplate, true)
        break;
      case "price":
        Log.info("price inputed")
        if(data.templatePrice === 0){
          updateCurrentTemplate.CostInVC = 0;
        }
        else{
          updateCurrentTemplate.CostInVC = data.templatePrice;
        }
        updatePrice(updateCurrentTemplate, true)
        break;
      default:
       break;
    }
  }

  const getValuesFromInputs = async ( optionSetter:string  = priceOption ) =>{
    const updateCurrentTemplate = {
      ...currTemplate,
    }
    const values = getValues();

    switch (optionSetter){ 
      case "free":
        Log.info("free")
        updateCurrentTemplate.CostInVC = 0;
         updatePrice(updateCurrentTemplate ,true)
        break;
      case "price":
        Log.info("price inputed")
        if(values.templatePrice === 0){
          updateCurrentTemplate.CostInVC = 0;
        }
        else{
          updateCurrentTemplate.CostInVC = values.templatePrice;
        }
        updatePrice(updateCurrentTemplate, true)
        break;
      default:
       break;
    }
  }

  const handleOnChange = async (e:React.ChangeEvent<HTMLInputElement>) => {
    getValuesFromInputs()
  }

  const updatePrice = async (updateCurrentTemplate: mbb.model.ITemplate, saveDraftFlag: boolean = false ) => {
    throttle(await dispatch(updateCurrentSelectedTemplatePrice(updateCurrentTemplate, saveDraftFlag)))
  }
  
    return (
        <div className={styles.container}>
      <div className={styles.content}>
        <div className={buttonHeaderStyles.innerButtonContainer}>
          <div>
            <h1>Price: {currTemplate?.AssetStoreItem?.Name}</h1>
          </div>
          <AssetHeaderButtons type="template" handleSaveClick={handleSubmit(onSaveClick)} />
        </div>

        <div style={{ marginBottom: "20px" }}>
          <PriceToolTip
            label={<h1 style={{ fontSize: "20px" }}>List Price in Buildbux</h1>}
            title={
              <div>
                <p>
                Buildbux is the native currency of Buildbox.  Users purchase Buildbux with real money and purchase all templates from the template store with Buildbux.
                </p>
                <p>                  
                  You may set the price of your Templates to however many Buildbux you choose, we recommend following these guidelines for the best results. 
                </p>
                <p style={{margin: "0px", padding: "0px"}}> Templates are typically sold for anywhere between 25 and 1000 Buildbux. </p>
              </div>
            }
          />
          <p className={styles.text}>
            Buildbox Shop Developer Terms:{" "}
            <a href='https://www.buildbox.com/shop-developer-terms/' target='_blank' rel='noopener noreferrer'>
              Learn more
            </a>
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            {/* <FormLabel id="demo-radio-buttons-group-label">Price $:</FormLabel> */}
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
              onChange={handleChange}
              value={priceOption}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  value={"price"}
                  control={<Radio style={{ color: "#FFBD00" }} />}
                  label={
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <CssTextField
                        autoFocus={false}
                        color='primary'
                        type='templatePrice'
                        size='small'
                        placeholder={"250"}
                        defaultValue={
                          currTemplate?.CostInVC?.toString() === "0"
                            ? "0"
                            : currTemplate?.CostInVC?.toString()
                        }
                        {...register("templatePrice", {
                          maxLength: 11,
                          valueAsNumber: true,
                          onChange: e => handleOnChange(e),
                        })}
                        disabled={disabledField}
                        error={errors.templatePrice ? true : false}
                      />
                    </div>
                  }
                />
              </div>
              <FormControlLabel value={"free"} control={<Radio style={{ color: "#FFBD00" }} />} label='Free' />
            </RadioGroup>
          </FormControl>
          
        </form>

        <div className={styles.buttonGroup}>
            <SubmitButton type="template" />
          </div>
      </div>
    </div>
    );
}
 
export default TemplatePrice;