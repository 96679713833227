import API from '@aws-amplify/api'
import Log from 'utils/Log';
import { formatDate } from 'utils/monetizationUtils';
import Axios, {AxiosResponse} from 'axios';
import AWS from "aws-sdk";

//TODO: Add the extra content
// This requires a body minimum of a asset name and type
// Will need to send it a body, because nothing is required at this pint
// Later on it will have to be the asset items that will b e used to process
type IAsset = mbb.model.IAsset

export const createInitialAsset= (token: string, body: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    API.post(`assetsURL`, '/marketplace/asset/create', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        "Content-Type": "application/json", //why did this work? .... f corsÍ›
        Token: token,
      },
      response: true,
      body: body //needs this to get the whole response object
    })
      .then((res) => {
        //201 status for new entry in data base in elastic   
        Log.info(res.data.payload)
        resolve(res.data.payload.result);
      })
      .catch((error) => {
        if (error.response) {
          Log.error(error.response)
          reject(error.response.data.error)
        }
      })
  })
}

export const fetchUserAssets = (token: string): any => {
  return new Promise((resolve, reject) => {

    API.get(`assetsURL`, '/marketplace/assets', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        // "Content-Type": "application/json", why did this work? .... f corsÍ›
        Token: token,
      },
      response: true, //needs this to get the whole response object
    }).then( async (res) => {
        let assetObjects: any = {};
        let soldArray: Record<string, number> = {};

        const promises = res.data.payload.assets.map( async (asset:IAsset) => {
          const soldCount = await fetchAssetSoldCount( token,asset.AssetStoreItem.Id)
          soldArray[soldCount.Id] = soldCount.Count;
          // return fetchAssetSoldCount(token, asset.AssetStoreItem.Id)
        })

        await Promise.all(promises)
    
        res.data.payload.assets.forEach((asset:IAsset) => {
          const currId = asset.Id;
          const count = soldArray[asset.AssetStoreItem.Id]
          assetObjects[currId] = asset;
          assetObjects[currId].SoldCount =count
        })
        // Log.info(assetObjects)
        resolve(assetObjects)
      })
      .catch((error: any) => {
        if (error.response) {
          reject(error.response.data.error)
        }
      })
  })
}

// Requirees field data created for the asset that was made and the asset version.
// Then we will send the up
export const getPresignedAssetData = async (  
  token: string, 
  // file: File,
  asset: IAsset
  ): Promise<AWS.S3.PresignedPost> => {
    const assetBody = { 
      'Id':  asset.AssetStoreItemId,
      'assetVersionId':  asset.Id,
      'assetVersion':  asset.Version
    }
    try{
      const res =  await API.post(`assetsURL`, '/marketplace/asset/preUpload', {
        headers: {
            'Access-Control-Allow-Origin': '*',
            Token: token,
          },
          response: true,
          body: assetBody
        })
      const presignedPostData:AWS.S3.PresignedPost = res.data.payload.url
      return presignedPostData;
    }catch(err) {
      throw err;
    }
}
// We should be able to upload any file from any location to any spot.
export const uploadFile = async (presignedPostData: AWS.S3.PresignedPost, file: File): Promise<AxiosResponse> => {
  try
  {
    // create a form obj
    let fd = new FormData();

    // append the fields in presignedPostData in formData
    Object.keys(presignedPostData.fields).forEach((key) => {
      fd.append(key, presignedPostData.fields[key])
    })
  
    // append the file
    fd.append("file", file);

    const response = await Axios.post(
      presignedPostData.url,
      fd,
    );

    return response;
  } catch (error)
    {
      console.error("Error attempting to upload File: ", error);

      throw error;
    }
}

export const uploadAnAsset = async (
  token: string, 
  asset: IAsset,
): Promise<any> => {
  try{
    const assetBody = { 
      'Id':  asset.AssetStoreItemId,
      'assetVersionId':  asset.Id,
      'assetVersion':  asset.Version
    }
  
    const res =  await API.post(`assetsURL`, '/marketplace/asset/upload', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        // "Content-Type": " application/octet-stream",
        Token: token,
      },
      response: true,
      body: assetBody
    })
    return res.data.payload
  } catch (err: any) {
    if(err){
      Log.error(err.response.data.error)
      throw err.response.data.error
    }
  }
}

export const uploadAssetPublicResource = (
  token: string, 
  file: File, //TODO: figure out the correct type for this
  asset: IAsset,
  resourceType: string,
): Promise<any> => {

  return new Promise((resolve, reject) => {

    // const url = resourceType === "thumbnail" ? "thumbnail" :"screenshot";
    let formData = new FormData()
    formData.append('Id', asset.AssetStoreItemId)
    formData.append('assetFile', file)
    formData.append('assetVersionId', asset.Id)
    formData.append('assetVersion', asset.Version)
    formData.append('assetStoreItemPageMediaId', asset.AssetStoreItemPageMediaId)
  
    API.post(`assetsURL`, `/marketplace/asset/${resourceType}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Token: token,
      },
      response: true,
      body: formData
    })
    .then((res) => {
      resolve(res.data.payload);
    })
    .catch((error) => {
      if (error) {
        Log.error(error.response.data.error)
        reject(error.response.data.error)
      }
    })
  })
}

export const saveCurrentAsset = (
  token: string, 
  asset: IAsset,
): Promise<any> => {
  return new Promise((resolve, reject ) => {
  
  const body = {
    ...asset,
    CostInVC: asset.AssetStoreItem.CostInVC
  }
  API.put(`assetsURL`, `/marketplace/asset/save`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Token: token,
      },
      response: true,
      body: body
    })
    .then((res) => {

      resolve(res.data.payload);
    })
    .catch((error) => {
      if (error) {
        Log.error(error.response.data.error)
        reject(error.response.data.error)
      }
    })
  })

}

export const deleteDraftAsset = (
  token: string,
  assetId: string
) : Promise<any> => {
  return new Promise((resolve, reject ) => {
    API.del(`assetsURL`, `/marketplace/asset/${assetId}/delete`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Token: token,
        },
        response: true,
      })
      .then((res) => {
        
        Log.info(res.data.payload);
        resolve(res.data.payload);
      })
      .catch((error) => {
        if (error) {
          Log.error(error.response.data.error)
          reject(error.response.data.error)
        }
      })
    })
}

export const submitAssetDraft = (
  token: string,
  asset: IAsset,
) : Promise<any> => {
  return new Promise((resolve, reject ) => {
    API.patch(`assetsURL`, `/marketplace/assets/submit`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Token: token,
        },
        body: {
          assetVersionId: asset.Id
        },
        response: true,
      })
      .then((res) => {
        
        Log.info(res.data.payload);
        resolve(res.data.payload);
      })
      .catch((error) => {
        if (error) {
          Log.error(error.response.data.error)
          reject(error.response.data.error)
        }
      })
  })
}

export const fetchdownloadAsset = ( 
  token: string,
  assetVersionId: string
) : Promise<{url: string, exp: Date}>  => {
  return new Promise((resolve, reject ) => {
    API.get(`assetsURL`, `/marketplace/download/${assetVersionId}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Token: token,
        },
        response: true,
      })
      .then((res) => {
        
        Log.info(res.data.payload);
        resolve(res.data.payload);
      })
      .catch((error) => {
        if (error) {
          Log.error(error.response.data.error)
          reject(error.response.data.error)
        }
      })
  })
}

export const fetchAssetSoldCount = ( 
  token: string,
  assetId: string
) : Promise<{Id: string , Count: number}>  => {
  return new Promise((resolve, reject ) => {
    API.get(`assetsURL`, `/marketplace/asset/${assetId}/count`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Token: token,
        },
        response: true,
      })
      .then((res) => {
        
        Log.info(res.data.payload);
        resolve(res.data.payload);
      })
      .catch((error) => {
        if (error) {
          Log.error(error.response.data.error)
          reject(error.response.data.error)
        }
      })
  })
}

export const uploadAssetChunk = (
  token: string,
  formdata: FormData
): Promise<{PartNumber:number, ETag:string }>  => {
  return new Promise((resolve, reject ) => {
    API.post(`assetsURL`, '/marketplace/asset/upload-large', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        "Content-Type": " application/octet-stream",
        Token: token,
      },
      response: true,
      body: formdata
    })
    .then((res) => {
      resolve(res.data.payload.response)
    })
    .catch((error) => {
      if (error.response.data.error) {
        Log.error(error.response.data.error)
        reject(error.response.data.error)
      }
      else {
        reject('Unable to process request at this time. Try again later.')
      }
    })
  }) 
}

export const uploadCompletAssetMultpartUpload = (
  token: string,
  assetVersionId: string,
  parts: {PartNumber:number, ETag:string }[]
): Promise<{Id: string, url: string}>  => {

  const body = {
    assetVersionId,
    parts
  }
  return new Promise((resolve, reject ) => {
    API.post(`assetsURL`, '/marketplace/asset/upload-large/complete', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        // "Content-Type": " application/octet-stream",
        Token: token,
      },
      response: true,
      body: body
    })
    .then((res) => {
      resolve(res.data.payload)
    })
    .catch((error) => {
      if (error) {
        if(error.response.data.error ){
          Log.error(error.response.data.error)
          reject(error.response.data.error)
        }
        else {
          reject('Unable to process request at this time. Try again later.')
        }
      }
    })
  }) 
}

export const uploadAssetChunkState = (
  token: string,
  assetVersionId: string,
  file: File): Promise<any> => {
  const body = {
    assetVersionId,
    fileName: file.name
  }
  return new Promise((resolve, reject ) => {
    API.post(`assetsURL`, '/marketplace/asset/upload-large/create', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        // "Content-Type": " application/octet-stream",
        Token: token,
      },
      response: true,
      body: body
    })
    .then((res) => {
      resolve(res.data.payload)
    })
    .catch((error) => {
      if (error) {
        Log.error(error.response.data.error)
        reject(error.response.data.error)
      }
    })
  }) 
}


export const priceChange = (
  token: string,
  assetVersionId: string,
  file: File): Promise<any> => {
  const body = {
    assetVersionId,
    fileName: file.name
  }
  return new Promise((resolve, reject ) => {
    API.post(`assetsURL`, '/marketplace/asset/price', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        // "Content-Type": " application/octet-stream",
        Token: token,
      },
      response: true,
      body: body
    })
    .then((res) => {
      resolve(res.data.payload)
    })
    .catch((error) => {
      if (error) {
        Log.error(error.response.data.error)
        reject(error.response.data.error)
      }
    })
  }) 
}

export const fetchLastMonthsEarnings = async (
  token: string
): Promise<mbb.model.AuthorEarningsReport> => {
  let response
  try {
    response = await API.get("assetsURL", "/reporting/lastmonth", {
      headers: {
        Token: token
      }
    })
  }
  catch (e:any) {
    Log.error(e.response?.data?.error)
    throw e
  }
  return response.payload
}

export const fetchThisMonthsEarnings = async (
  token: string
): Promise<mbb.model.AuthorEarningsReport> => {
  let response
  try {
    response = await API.get("assetsURL", "/reporting/thismonth", {
      headers: {
        Token: token
      }
    })
  }
  catch (e:any) {
    Log.error(e.response?.data?.error)
    throw e
  }
  return response.payload
}

export const fetchEarningsReport = (
  token: string,
  start: Date,
  end: Date
  ): Promise<any> => {
  return new Promise((resolve, reject ) => {
    API.get(`assetsURL`, `/reporting/${formatDate(start)}/${formatDate(end)}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        // "Content-Type": " application/octet-stream",
        Token: token,
      },
      response: true
    })
    .then((res) => {
      resolve(res.data.payload)
    })
    .catch((error) => {
      if (error) {
        Log.error(error.response.data.error)
        reject(error.response.data.error)
      }
    })
  })
}

export const fetchTransactionHistory = (
  token: string
): Promise<any> => {
  return new Promise((resolve, reject ) => {
    API.get(`assetsURL`, `/reporting/transactions`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        // "Content-Type": " application/octet-stream",
        Token: token,
      },
      response: true
    })
    .then((res) => {
      resolve(res.data.payload)
    })
    .catch((error) => {
      if (error) {
        Log.error(error.response.data.error)
        reject(error.response.data.error)
      }
    })
  })
}
