import { FormHelperText, FormControl, Select, MenuItem, SxProps, styled } from "@mui/material";
import GenericButton from "common/components/Buttons/GenericButton";
import SubmitButton from "common/components/Buttons/SubmitButton";
import GenericInputLabel, { CssTextField } from "common/components/Inputs/GenericInputLabel";
import InputTags from "common/components/Tags/GenericTags";
import GenericToolTip from "common/components/ToolTip/GenericToolTip";
import AssetHeaderButtons from "pages/DashboardPage/components/AssetHeaderButtons";
import React, { FunctionComponent, ReactEventHandler, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TemplateStoreItemType } from "./ModalCreateNewTemplate";
import styles from "styles/TemplateDetails.module.scss"
import buttonHeaderStyles from "styles/SelectedTemplatePage.module.scss"
import { currentSelectedTemplate } from "redux/template/templateSelectors";
import { updateCurrentSelectedTemplate } from "redux/template/templateThunks";
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { assetValidateForSaveDraft } from "utils/ValidateFormUtils";

export const TCssTextField = styled(CssTextField)({
    marginLeft: "7px", 
  });

interface TemplateDetailsProps {

}

type Inputs = {
    templateName: string
    templateLongDescription: string
    templateVersion: string
    templateType: TemplateStoreItemType | string
    // templateKeyWords: string
    templateDescription: string
}

const selectStyling = {
    select: {
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ffbd00",
        },
        "&:focus .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ffbd00",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ffbd00",
        },
    },
}

type TemplateOrientation = mbb.model.TemplateOrientation

const TemplateDetails: FunctionComponent<TemplateDetailsProps> = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [disableField, setDisableField] = useState<boolean>(false)
    const currSelectedTemplate = useSelector(currentSelectedTemplate)

    const [editorState, setEditorState] = React.useState<EditorState>()

    const {
        reset,
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        setValue
    } = useForm<Inputs>()

    const newTags = currSelectedTemplate?.AssetStoreItemPageMedia?.Keywords?.length > 0 ? currSelectedTemplate?.AssetStoreItemPageMedia?.Keywords?.split(" ") : null

    const [tags, setTags] = useState<string[] | null>(newTags)

    useEffect(() => {
        setValues()
    }, [currSelectedTemplate])

    useEffect(() => {
        const contentBlock = htmlToDraft(currSelectedTemplate?.AssetStoreItemPageMedia?.DetailedDescription)
        
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorStateInit = EditorState.createWithContent(contentState) as EditorState;
            setEditorState(editorStateInit)
          }
        setValues()
    }, [])

    useEffect(() => {
      onSaveDraft()
    },[tags])

    useEffect(() => {
        const debounceChangeEventI = setTimeout(() => {
            onSaveDraft()
        }, 2000)

        return () => clearTimeout(debounceChangeEventI)
    }, [editorState])

    const setValues = async () => {
        reset({
            templateName: currSelectedTemplate?.AssetStoreItem?.Name,
            templateLongDescription: currSelectedTemplate?.AssetStoreItemPageMedia?.DetailedDescription,
            templateVersion: currSelectedTemplate?.Version,
            templateType: currSelectedTemplate?.Orientation,
            templateDescription: currSelectedTemplate?.AssetStoreItemPageMedia?.Description,
        })

        // setTags(currSelectedTemplate?.AssetStoreItem.Tags.length > 0 ? currSelectedTemplate.Tags : null)
        // TODO: No tags?

        if (
            currSelectedTemplate?.Status === "SUBMITTED" ||
            currSelectedTemplate?.Status === "IN_REVIEW" ||
            currSelectedTemplate?.Status === "FOR_SALE" ||
            currSelectedTemplate?.Status === "DELETED"
        ) {
            setDisableField(true)
        } else {
            setDisableField(false)
        }
    }

    const onSubmit: SubmitHandler<Inputs> = async data => {
        try {
            if( assetValidateForSaveDraft(currSelectedTemplate )) {
                const updateCurrentTemplate = getValuesFromInputs()
                dispatch(updateCurrentSelectedTemplate(updateCurrentTemplate, true))
                   
            }
            navigate(`/templates/a/${currSelectedTemplate.Id}/upload`)
        } catch (err) {
          
        }
    }

    const onSaveDraft = async () => {
        const updateCurrentTemplate = getValuesFromInputs()
        if (!updateCurrentTemplate.Id) {
            return
        }
        try {
                        
           if (updateCurrentTemplate.Id && assetValidateForSaveDraft(currSelectedTemplate)) {
            await dispatch(updateCurrentSelectedTemplate(updateCurrentTemplate, true))
           }
            // navigate(`/dashboard/a/${currSelectedTemplate.Id}/upload`)
        } catch (err) {
        }
    }

    const getValuesFromInputs = () => {
        const data = getValues()

        const updateCurrentTemplate = {
            ...currSelectedTemplate,
        }

        updateCurrentTemplate.AssetStoreItem.Name = data.templateName
        updateCurrentTemplate.Orientation = data.templateType as TemplateOrientation
        updateCurrentTemplate.AssetStoreItemPageMedia.Description = data.templateDescription
        updateCurrentTemplate.AssetStoreItemPageMedia.DetailedDescription = data.templateLongDescription

        if (tags === null) {
            updateCurrentTemplate.AssetStoreItemPageMedia.Keywords = [].join(" ")
        } else {
            updateCurrentTemplate.AssetStoreItemPageMedia.Keywords = tags.join(" ")
        } 

        return updateCurrentTemplate
    }

    // FIXME: Very expensive OP. Maybe debounce like in Richtext editor?
    const handleOnChange = async (e: ReactEventHandler) => {
        const values = getValuesFromInputs()
        await dispatch(updateCurrentSelectedTemplate(values, false))
    }

    const handleEditorStateChange = (e: EditorState) => {
        setEditorState(e)
        setValue('templateLongDescription', draftToHtml(convertToRaw(e.getCurrentContent())))
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={buttonHeaderStyles.innerButtonContainer}>
                    <div>
                        <h1>Details: {currSelectedTemplate?.AssetStoreItem?.Name}</h1>
                    </div>
                    <AssetHeaderButtons type="template" handleSaveClick={handleSubmit(onSaveDraft)} disabled={ !assetValidateForSaveDraft(currSelectedTemplate) } />
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    <div className={styles.firstInputSpacing}>
                        <GenericToolTip
                            label='Template Name'
                            title={
                                <div>
                                  <p>Alphanumeric name that will be displayed on the main Buildbox Shop page.  A short description of the style of your game often works best! Examples: 3D Space Shooter, Single Player Quiz, or 2D Jungle Platformer</p>
                                </div>
                            }
                        />
                        <TCssTextField
                            color='primary'
                            type='templateName'
                            placeholder={currSelectedTemplate?.AssetStoreItem?.Name}
                            size='small'
                            defaultValue={currSelectedTemplate?.AssetStoreItem?.Name}
                            error={errors.templateName ? true : false}
                            
                            {...register("templateName", {
                                required: true,
                                maxLength: 30,
                                // pattern: /^[A-Za-z0-9\s!@#$%^&*()_+=-`~\\\]\[{}|';:/.,?><]*$/,
                                onChange: e => {
                                    handleOnChange(e)
                                },
                            })}
                            className={styles.inputStyle}
                            disabled={disableField}
                        />
                        {errors.templateName ? (
                            <FormHelperText
                                id='component-helper-text'
                                sx={{ color: "red", marginLeft: "13px", marginTop: "-1px" }}
                                error={errors.templateName ? true : false}
                            >
                                Template Name conditions were not met
                            </FormHelperText>
                        ) : (
                            " "
                        )}
                    </div>
                    <div className={styles.inputSpacing}>
                        <GenericInputLabel
                            htmlFor='outlined-adornment-password'
                            // color='warning'
                            inputLabelStyles={styles.inputLabel}
                        >
                            Template Version:
                        </GenericInputLabel>
                        
                        <TCssTextField
                            
                            color='primary'
                            type='templateVersion'
                            placeholder={currSelectedTemplate?.Version}
                            disabled
                            size='small'
                            defaultValue={currSelectedTemplate?.Version}
                            
                            {...register("templateVersion", {
                                pattern: /^[0-9]+[.][0-9]+[.][0-9]+$/i,
                                onChange: e => {
                                    handleOnChange(e)
                                },
                            })}
                            className={styles.inputStyle}
                        />
                    </div>
                    <div className={styles.inputSpacing}>
                        <div style={{ width: "200px" }}>
                            <GenericToolTip
                                label='Template Orientation'
                                title={
                                    <div>
                                        <p>
                                            When a user previews your game, does it default to portrait or landscape? Your store page layout reflects this choice.
                                        </p>
                                    </div>
                                }
                            />
                            <FormControl fullWidth>
                                <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    defaultValue={currSelectedTemplate?.Orientation}
                                    // value={category}
                                    // value={}
                                    {...register("templateType", {
                                        required: true,
                                        onChange: e => {
                                            handleOnChange(e)
                                        },
                                    })}
                                    className={styles.inputStyle}
                                    style={{ minWidth: "155px" }}
                                    disabled={disableField}
                                    sx={selectStyling.select}
                                >
                                    <MenuItem value={TemplateStoreItemType["LANDSCAPE"]}>LANDSCAPE</MenuItem>
                                    <MenuItem value={TemplateStoreItemType["PORTRAIT"]}>PORTRAIT</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    
                    <div className={styles.inputSpacing}>
                        <GenericToolTip
                            label={"Short Description"}
                            title={
                                <div>
                                    <p>
                                        The hover text shown on the main Buildbox Store page. A sentence or two to highlight some of the cool included features, art, or other top selling points of your game.  Example: Tap to shoot your way through 10 levels and more than a dozen unique enemies in this urban sidescroller.
                                    </p>
                                </div>
                            }
                        />
                        <TCssTextField
                            id='outlined-multiline-flexible'
                            // label="Long Description"
                            multiline
                            minRows={4}
                            className={styles.inputStyle}
                            placeholder={currSelectedTemplate?.AssetStoreItemPageMedia?.Description}
                            defaultValue={currSelectedTemplate?.AssetStoreItemPageMedia?.Description}
                            error={errors.templateDescription ? true : false}
                            
                            disabled={disableField}
                            {...register("templateDescription", {
                                required: true,
                                maxLength: 1000,
                                // pattern: /^[A-Za-z0-9\s!@#$%^&*()_+=-`~\\\]\[{}|';:/.,?><]*$/,
                                onChange: e => {
                                    handleOnChange(e)
                                },
                            })}
                        />
                        {errors.templateDescription ? (
                            <FormHelperText
                                id='component-helper-text'
                                sx={{ color: "red", marginLeft: "13px", marginTop: "-1px" }}
                                error={errors.templateDescription ? true : false}
                            >
                                Description conditions were not met
                            </FormHelperText>
                        ) : null}
                    </div>


                    <div className={styles.inputSpacing}>
                        <GenericToolTip
                            label={"Long Description"}
                            title={
                                <div>
                                    <p>
                                        When a user taps "More" from the main Buildbox Shop template page, they will see this rich text description of your game.  Feel free to go into as much detail as you want here.  Headers, bulleted lists, and other text format options help you craft a more user friendly, easily read store page.
                                    </p>
                                </div>
                            }
                        />
                        
                        {/* Rich text editor */}
                        {!disableField && <Editor
                            editorStyle={{ border: 'none', minHeight: '200px', backgroundColor: '#ffffff', width: '100%', borderRadius: '5px', marginLeft: '7px' }}
                            onEditorStateChange={(e: EditorState) => handleEditorStateChange(e)}
                            handlePastedText={() => false}
                            placeholder="Type here..."
                            editorState={editorState}
                            toolbarClassName={styles.toolbarCustom}
                        />}
                        {disableField &&
                            <TCssTextField
                            id='outlined-multiline-flexible'
                            // label="Long Description"
                            multiline
                            minRows={4}
                            className={styles.inputStyle}
                            // placeholder={currSelectedTemplate?.AssetStoreItemPageMedia?.Description}
                            value={draftToHtml(convertToRaw((editorState as EditorState).getCurrentContent()))}
                            error={errors.templateLongDescription ? true : false}
                            
                            disabled={disableField}
                            // {...register("templateDescription", {
                            //     required: true,
                            //     maxLength: 1000,
                            //     // pattern: /^[A-Za-z0-9\s!@#$%^&*()_+=-`~\\\]\[{}|';:/.,?><]*$/,
                            //     onChange: e => {
                            //         handleOnChange(e)
                            //     },
                            // })}
                        />
                        }
                        {errors.templateLongDescription ? (
                            <FormHelperText
                                id='component-helper-text'
                                sx={{ color: "red", marginLeft: "13px", marginTop: "-1px" }}
                                error={errors.templateLongDescription ? true : false}
                            >
                                Detailed Description conditions were not met
                            </FormHelperText>
                        ) : null}
                    </div>
                    <div>
                    <GenericToolTip
                        label='Tags'
                        title={
                            <div>
                                <p>10 tags max. Use the most relevant tags to allow customers to find your template.</p>
                                {/* <p>Please Note: Don’t use words relating to “Type” as they are auto-added.</p> */}
                            </div>
                        }
                    />
                    
                </div>
                </form>
                <div className={styles.tagsContainer}>
                    <p>{"(hint: press enter to add, no spaces allowed)"}</p>
                    <InputTags tags={tags} SetTags={setTags} inputStyles={styles.inputStyle} customBoxStyles={{marginLeft: '7px'}} />
                    </div>
                <div className={styles.buttonGroup}>
                    <GenericButton
                        onClick={handleSubmit(onSubmit)}
                        variant='filled'
                        text='Submit'
                        buttonStyles={styles.nextButton}
                    >
                        Next
                    </GenericButton>
                    <SubmitButton type="template" />
                </div>
            </div>
        </div>
    );
}

export default TemplateDetails;