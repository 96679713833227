import React, { useEffect, useState } from 'react'
import './App.css'
import NavMenu from 'common/components/NavigationMenu';
import ScreenNameSetup from 'pages/LandingPage/components/ScreenNameSetup';
// import PageContainer from './common/container/PageContainer'
import PageRoutes from './PageRoutes'
// import { getCognitoUser } from './utils/CognitoUtils';
import { useSelector, useDispatch } from 'react-redux';
import { currentUser, currentUserScreenName} from 'redux/auth/authSelectors';
import { useNavigate } from 'react-router-dom';
import routes from 'common/constants/routes.json'
import { getUIMessage } from 'redux/ui/uiSelectors';
import SnackBarAlert from 'common/components/Alerts/SnackBarAlert';
import { loginUser, logout } from 'redux/auth/authThunks';
import Log from 'utils/Log';
import { setMessage } from 'redux/ui/uiActions';
import { Hub } from 'aws-amplify';

function App() {
  const user = useSelector(currentUser);
  const userScreenName = useSelector(currentUserScreenName)
  const navigate = useNavigate();
  const [navOpen, setNavOpen] = useState(false);
  const uiMessage = useSelector(getUIMessage)
  const dispatch = useDispatch();

  useEffect(() => {
    if(user === null){
      setNavOpen(false)
      navigate(routes.LOGIN)
    }
    if(userScreenName === "" || userScreenName === null ){
      setNavOpen(false)
      navigate(routes.SCREENNAME_SET_UP);
    }
    else { 
      setNavOpen(true)
    }
  }, [user, userScreenName])


  useEffect(() => {
    const handler = async (props: any) => {
      switch (props.payload.event) {
        case "signIn":
          Log.info("", "sign in heard");
          try {
            await dispatch(loginUser(props.payload));
            // const isFromBBClient = sessionStorage.getItem("fromBBClient");

            //   if (isFromBBClient === "true") {
            //     await dispatch(clientLoginRedirect());
            //   } else {
            //     await dispatch(webLoginRedirect(props));
            //   }
            //   setIsAuthenticated(true);
            if(user === null){
              setNavOpen(false)
              navigate(routes.LOGIN)
            }
            if(userScreenName === "" || user.isNewUser ){
              setNavOpen(false)
              navigate(routes.SCREENNAME_SET_UP);
            }
            else {
              setNavOpen(true)
              navigate(routes.DASHBOARD);
            }
          } catch (e: any) {
            Log.error(e, "App.js: error caught signing in!!!");
            // setAuthError(e);
            // dispatch(showError(e.message));
            if(e.message.include('isNewUser')){
              return
            }
            dispatch(setMessage({ message: e.message, severity: "error" }))
          }
          break;

        case "signIn_failure":
          Log.error(props.payload.data, "App Component - error caught on signIn");

          if (props.payload.data.message.includes("invalid_grant") && !currentUser) {

            dispatch(setMessage({ message: `Something went wrong. Please sign out and log in.`, severity: "error" }))
            navigate(routes.LOGIN);
          } else {
            // redirectToAuthUI(props, payload.data);
          }
          break;
        case "signOut":
          navigate(routes.CLIENT_LOGOUT_REDIRECT);
          // navigate(routes.LOGIN);
          break;
        default:
          break;
      }
    };

    Hub.listen("auth", handler);

    //clean up any events
    return () => {
      Hub.remove("auth", handler);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div className='App'>
     { (user !== null && userScreenName !== "") && <NavMenu />}  
     { (uiMessage && uiMessage.message !== '') && <SnackBarAlert UIMessage={{message: uiMessage.message,  severity: uiMessage.severity}}/> } 
     {/* {(userScreenName === null && user.screenName === "") && <ScreenNameSetup />}   */}
      <header className='App-header'>
        <PageRoutes />
      </header>
    </div>
  )
}

export default App
