import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import InputLabel from '@mui/material/InputLabel';
import buttonStyles from 'styles/_buttons.module.scss';

// const defaultTheme = createTheme();
// const theme = createTheme({
//   components: {
//     MuiTooltip: {
//       styleOverrides: {
//         tooltip: {
//           fontSize: "2em",
//           color: "yellow",
//           backgroundColor: "red"
//         }
//       }
//     }
//   }
// });

// export const TextOnlyTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
// ))(`
//     color: black;
//     background-color: transparent;
// `);

const GenericToolTip = (props) => {

  return (
    <InputLabel id="demo-simple-select-label" style={{ color: "#000000", alignItems: "center", alignContent: "center",
    display: "flex", fontSize: "14px" }}>{props.label}: 
      <div style={{alignItems: "center", alignContent: "center",
    display: "flex", justifyContent: "center"}}>
        <Tooltip style={{color: "#ABABAB", cursor: "pointer", 
          height: "20px",
          "&:hover": {
            color: "green",
            fill: "green"
          }
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#282828',
            color: "white",
            fontSize: "12px",
            margin: "10px, 14px, 14px, 12px",
            width: "218px",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            '& .MuiTooltip-arrow': {
              color: '#282828',
            },
          },
        },
      }}
      title={props.title} arrow placement="top-start">
          <ErrorOutlineIcon className={buttonStyles.tooltip}/>
        </Tooltip>
      </div> 
    </InputLabel>

  )
}

export const PriceToolTip = (props) => {

  return (
    <InputLabel id="demo-simple-select-label" style={{ color: "#000000", alignItems: "center", alignContent: "center",
    display: "flex", fontSize: "14px" }}>{props.label}: 
      <div style={{alignItems: "center", alignContent: "center",
    display: "flex", justifyContent: "center"}}>
        <Tooltip style={{color: "#ABABAB", cursor: "pointer", 
          height: "20px",
          "&:hover": {
            color: "green",
            fill: "green"
          }
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#282828',
            color: "white",
            fontSize: "12px",
            margin: "10px, 14px, 14px, 12px",
            width: "218px",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            '& .MuiTooltip-arrow': {
              color: '#282828',
            },
          },
        },
      }}
      title={props.title} arrow placement="top-start">
          <ErrorOutlineIcon className={buttonStyles.tooltip}/>
        </Tooltip>
      </div> 
    </InputLabel>

  )
}

export default GenericToolTip
