import { AnyAction } from 'redux'
import actions from '../actionTypes.json'

const initialState: mbb.state.IUI = {
  navDrawerOpen: true,
  loading: false,
  uiMessage: {
    message: '',
    severity: '',
  },
  assetSubmitSuccess: false,
  assetUploading: false,
  imageUploading: false,
  assetUploadProgress: 0,
  earningsLoading: false,
  templateSubmitSuccess: false,
  templateUploading: false,
  templateUploadProgress: 0,
};

const uiReducer = (
  state: mbb.state.IUI = initialState,
  action: AnyAction
): mbb.state.IUI => {
  switch (action.type) {
    case actions.UI.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actions.UI.SET_MESSAGE:
      return {
        ...state,
        uiMessage: {
          message: action.uiMessage.message,
          severity: action.uiMessage.severity,
        },
      };
    case actions.UI.REMOVE_MESSAGE:
      return {
        ...state,
        uiMessage: {
          message: '',
          severity: '',
        },
      };
    case actions.UI.SET_OPEN_NAV_DRAWER:
      return {
        ...state,
        navDrawerOpen: action.open,
      };
    case actions.UI.SET_ASSET_SUBMIT:
      return {
        ...state,
        assetSubmitSuccess: action.success,
      };
    case actions.UI.ASSET_UPLOADING:
      return {
        ...state,
        assetUploading: action.loading,
      };
    case actions.UI.IMAGE_UPLOADING:
      return {
        ...state,
        imageUploading: action.loading,
      };
    case actions.UI.SET_ASSET_PROGRESS:
      return {
        ...state,
        assetUploadProgress: action.progress,
      };
    case actions.UI.SET_EARNINGS_LOADING:
      return {
        ...state,
        earningsLoading: action.loading,
      };
    case actions.UI.RESET_UI:
      return initialState;
    case actions.UI.SET_TEMPLATE_SUBMIT:
      return {
        ...state,
        templateSubmitSuccess: action.success,
      };
    case actions.UI.TEMPLATE_UPLOADING:
      return {
        ...state,
        templateUploading: action.loading,
      };
    case actions.UI.SET_TEMPLATE_PROGRESS:
      return {
        ...state,
        templateUploadProgress: action.progress,
      };
    default:
      return state;
  }
};

export default uiReducer;
