import Auth from '@aws-amplify/auth'
// import API from '@aws-amplify/api'

type ICognitoUser = mbb.model.ICognitoUser

export const checkTokenExpiration = async (token:string) => {
  if (token) {
    try {
      const parseJwt = JSON.parse(atob(token.split(".")[1]));

      const { exp } = await parseJwt;

      // get current epoch time
      const currentTimeStamp = new Date().getTime() / 1000;

      if (currentTimeStamp >= exp) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // Log.error(error, "error caught in checkTokenExpiration");
      return null;
    }
  }
};


export const getCognitoUser = async (): Promise<ICognitoUser> => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.accessToken.jwtToken
    const refreshToken = user.signInUserSession.refreshToken.token
    const { email, name } = user.attributes
    const userObject: ICognitoUser = {
      email,
      name,
      accessToken: token,
      refreshToken,
    }
    return userObject
  } catch (e) {
    throw e
  }
}


export const refreshWebAccessToken = async () => {
  // Log.info("", "refreshWebAccessToken called");

  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession:any = await Auth.currentSession();

  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(
      currentSession.refreshToken,
      (error:any, session:any) => {

        if (error) {
          // Log.error(error, "error caught in while refreshing web client token");
          reject(error);
        }
        const accessToken = session.accessToken.jwtToken;

        resolve(accessToken);
      }
    );
  });
};

export const getRefreshedCognitoTokens = async (
  refreshToken:string,
  cognitoClientId:string,
  clientSecretHash:string
):Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(
      `https://cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/`,
      {
        headers: {
          "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
          "Content-Type": "application/x-amz-json-1.1",
        },
        mode: "cors",
        cache: "no-cache",
        method: "POST",
        body: JSON.stringify({
          ClientId: cognitoClientId,
          AuthFlow: "REFRESH_TOKEN_AUTH",
          AuthParameters: {
            REFRESH_TOKEN: refreshToken,
            SECRET_HASH: clientSecretHash, // add client secret if there is one
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Log.info("data from get refresh cognito tokens", data);

        if (!data.AuthenticationResult) {
          reject(data);
        }

        resolve(data.AuthenticationResult.AccessToken);
      })
      .catch((error) => {
        // Log.error(error, "error caught in while calling REFRESH_TOKEN_AUTH");
        reject(error);
      });
  });
}
