import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import styles from 'styles//Navbar.module.scss'
import Drawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import routes from '../../constants/routes.json'
import { NavLink } from 'react-router-dom'
import { drawerWidth, navItems } from 'common/constants/menuConstants'
import GenericButton from '../Buttons/GenericButton'
import signOutIcon from 'assets/images/16x16/Sign Out.svg'
import headerImg from 'assets/images/BB_Shop.png'
import { useDispatch } from 'react-redux'
import { logout } from 'redux/auth/authThunks'

const PermanentDrawerLeft = () => {
  const dispatch = useDispatch()

  const handleSignOutClick = async () => {

    await dispatch(logout());
  }
  return (
    <div>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            borderRight: '1px solid black',
          },
        }}
        variant='permanent'
        anchor='left'
        className={styles.drawer}
      >
        <div className={styles.drawerHeader}>
          {/* <Typography variant='h5'>Market Place</Typography> */}
          <img src={headerImg} />
        </div>
        <List className={styles.navLinks}>
          {navItems.map((item, index) => (
            <NavLink
              key={index}
              to={item.path}
              className={({ isActive }) => (isActive ? styles.active : '')}
            >
              <ListItem key={item.title} className={styles.links}>
                <ListItemIcon className={styles.itemIcon}>
                  <img src={item.icon} />
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            </NavLink>
          ))}
        </List>
        <div className={styles.signOutContainer}>
          <GenericButton
            variant='default'
            text='Sign Out'
            onClick={handleSignOutClick}
            buttonStyles={styles.signOutButtonStyle}
            icon={signOutIcon}
          >
            Sign Out
          </GenericButton>
        </div>
      </Drawer>
    </div>
  )
}

export default PermanentDrawerLeft
