import { IconButton } from "@mui/material";
import GenericButton from "common/components/Buttons/GenericButton";
import SubmitButton from "common/components/Buttons/SubmitButton";
import CircularLoading from "common/components/Loading/Circular";
import GenericToolTip from "common/components/ToolTip/GenericToolTip";
import AssetHeaderButtons from "pages/DashboardPage/components/AssetHeaderButtons";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTemplateUploadingState } from "redux/ui/uiSelectors";
import styles from 'styles/TemplateUpload.module.scss';
import DownloadIcon from '@mui/icons-material/Download';
import buttonHeaderStyles from 'styles/SelectedTemplatePage.module.scss';
import fileIcon from 'assets/images/BBAsset_35x46_x2.png';
import { currentSelectedTemplate, getUploadTemplateLoaded, setTemplateDownloadLink } from "redux/template/templateSelectors";
import { downloadTemplate, saveDraftAuthoredTemplate, uploadFileTemplate } from "redux/template/templateThunks";
import { downloadFile } from "utils/HelperUtils";
import { assetValidateForSaveDraft } from "utils/ValidateFormUtils";

interface UploadTemplateFilesProps {
    
}
 
const UploadTemplateFiles: FunctionComponent<UploadTemplateFilesProps> = () => {
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  // const [file, setFile] = React.useState<File | null>(null);
  const currentTemplate = useSelector(currentSelectedTemplate);
  const dispatch = useDispatch();
  // const loading         = useSelector(getLoadingState)
  const loaded = useSelector(getUploadTemplateLoaded)
  const navigate = useNavigate();
  const [disableUpload, setDisableUpload] = useState<boolean>(false);
  const templateDownloadLink = useSelector(setTemplateDownloadLink);
  const templateUploading = useSelector(getTemplateUploadingState);
  // const templateProgress = useSelector(getTemplateUploadProgress);

  useEffect(() => {
    if(loaded) {
      // setFile(null)
    }
    checkDisableUpload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [loaded])

  useEffect(() => {
    checkDisableUpload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate ])

  //----------------------------------------------------------------------------------
  // Event Handlers
  //----------------------------------------------------------------------------------  
  const handleSelectFileClick = async(event: any) => {

    
     hiddenFileInput?.current?.click();
  };

  const handleChange = async (event: any) => {
    const fileUploaded = event.target.files[0];

    
    // setFile(fileUploaded);
    await dispatch(uploadFileTemplate(fileUploaded));
  };

  const handleNextButtonClick = async () => {
    try{
      if(assetValidateForSaveDraft(currentTemplate)){
        await dispatch(saveDraftAuthoredTemplate())
      }
      navigate(`/templates/a/${currentTemplate.Id}/preview-images`)
    } catch(err) {

    }
  };

  const handleDownloadClick = async () => {
    try{
      dispatch(downloadTemplate(currentTemplate.Id));
      // downloadFile(url);
    } catch (err) {

    }
  }

  const checkDisableUpload = async () => {
    if(currentTemplate?.Status === "IN_REVIEW" || currentTemplate?.Status === "SUBMITTED" || currentTemplate?.Status === "FOR_SALE" )
    setDisableUpload(true)
  }

  const onSaveDraft = async () => {
    try {
      if(currentTemplate?.Status === "REMOVED_FROM_SALE" || currentTemplate?.Status === "NOT_READY" || currentTemplate?.Status === "REJECTED" ){
        await dispatch(saveDraftAuthoredTemplate())
      }
        // navigate(`/dashboard/a/${currSelectedTemplate.Id}/upload`)
    } catch (err) {
        console.error(err)
    }
  }


    return (
        <div className={styles.container}>
      <div className={styles.content}>
        <div className={buttonHeaderStyles.innerButtonContainer }>
          <div>
          <h1>Upload Template: {currentTemplate?.AssetStoreItem?.Name}</h1>  
          </div>
          <AssetHeaderButtons type="template" handleSaveClick={onSaveDraft} disabled={!assetValidateForSaveDraft(currentTemplate)}/>
        </div>
  
        <div className={styles.uploadContainer}>
          <div>
          <GenericToolTip
              label='Template File'
              title={
                <div>
                    <p>This is where you upload your actual template .bbdoc (saved Buildbox project file).</p>
                   <p>Max File Size: 2 GB</p>
                </div>
              }
            />
          </div>
          
        </div>
      
        <div className={styles.assetFileContainer}>
          <input
            title="file"
            type="file"
            accept=".bbdoc"
            ref={hiddenFileInput}
            onChange={handleChange}
            className={styles.hiddenFileInput}
          />   

          <button className={styles.uploadFileButton}
            onClick={handleSelectFileClick}
            disabled={disableUpload}
          >
            Upload File
          </button>
          {  currentTemplate?.TemplateUrl && 
            <div style={{width: "170px"}}>
              <button className={styles.uploadFileButton} style={{marginLeft: 20}} onClick={handleDownloadClick}>
                Download Template
              </button>
            </div> 
          }
          </div>
          { (currentTemplate?.TemplateUrl && !templateUploading )&& 
            <div className={styles.fileNameContainer}>
                <img className={styles.fileIcon} src={fileIcon} alt="file icon"/>
                <span className={styles.fileName}>{currentTemplate?.AssetStoreItem.Name}.bbdoc</span>
              { templateDownloadLink && downloadFile(templateDownloadLink) } 
            </div>
          }
            <div className={styles.loadingContainer}>
              {templateUploading && <CircularLoading/>}
            </div>


        <div className={styles.nextButtonContainer}>
          <GenericButton 
            variant="filled"
            buttonStyles={styles.nextButton}
            onClick={handleNextButtonClick}
          >
            Next
          </GenericButton>
          <SubmitButton type="template"/>
        </div>
      </div>
    </div>
    );
}
 
export default UploadTemplateFiles;