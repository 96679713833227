import { DefaultThunkAction } from '../shared'
import { handleLoading } from '../ui/uiThunks';
import { 
  setAssetScreenShotUrl, 
  setCurrentAsset, 
  setUserAssets, 
  setSaveCurrentAsset,
  removeAssetFromList,
  setAssetStatus,
  setAssetCount,
  setAssetDownloadLink
} from './assetActions'
import { setAssetProgress, setAssetSubmitSuccess, setAssetUploadLoading, setImageUploading, setMessage } from '../ui/uiActions'
import Log from 'utils/Log';
import { 
  createInitialAsset,
  fetchUserAssets,
  uploadAssetPublicResource,
  saveCurrentAsset,
  deleteDraftAsset,
  submitAssetDraft,
  fetchdownloadAsset,
  fetchAssetSoldCount,
  getPresignedAssetData,
  uploadFile,
  uploadAnAsset
} from 'api/assets';
import {
  validateToken
} from '../auth/authThunks';

import { push } from "connected-react-router";

type IAsset = mbb.model.IAsset

//TODO: 
export function getAllAuthoredAssets(): DefaultThunkAction<Promise<any>> { 
  return async (dispatch, getState) => {
    dispatch(handleLoading(true))
    const accessToken = await dispatch<Promise<string>>(validateToken());
    try {

      const assetList = await fetchUserAssets(accessToken);


      dispatch(setUserAssets(assetList))
      dispatch(handleLoading(false))
      return assetList
    } catch (error) {

      dispatch(handleLoading(false))

      throw error;
    }
  };
}

// TODO: bring
export function initCreateAuthoredAsset(data: any): DefaultThunkAction<Promise<any>> { 
  return async (dispatch, getState) => {
    dispatch(handleLoading(true))
    const accessToken = await dispatch<Promise<string>>(validateToken());
    try {
      const { assets } = getState();


      let body: any = {
        Name: data.assetName,
        version: "1.0.0",
        type: data.assetType,
        description: data?.assetDescription ?  data.assetDescription : ""
      }
    
      const newAsset:any = await createInitialAsset(accessToken, body );
      let newAssetsObj: any = {
        ...assets.assets, 
       [ newAsset.Id]: newAsset
      };

      await dispatch(setUserAssets(newAssetsObj))
      await dispatch(handleLoading(false))
      
      // navigate(`/dashboard/a/${newAsset.Id}/details`)
      dispatch(push(`/dashboard/a/${newAsset.Id}/details`))
      // dispatch(push(`/dashboard/a/test/details`))

      return newAsset.Id
    } catch (error) {
      // Log.error(error, "Error dispatching: validateToken");
      dispatch(handleLoading(false))
      dispatch(setMessage({ message: `${error}`, severity: 'error'}))
    }
  }
}

export function setSelectedCurrentAsset(assetVersionId: string ): DefaultThunkAction<Promise<any>> {
  return async (dispatch, getState) => {
    dispatch(handleLoading(true))
    const { assets } = getState()

    let currAsset = assets.assets[assetVersionId]; // get the asset from assets dict
    try{ 

      if( currAsset === undefined){
        // api -> "assetmanagment/myauthoredassets/:assetVersionId"
        // logic sto items...
      }
      if ( assets.currentAsset != null ){
        if(currAsset.Id !== assets.currentAsset?.Id){
          // prompt user if they want to save currentAsset before making the switch to the new currAsset
        }
      }
      
      await dispatch(setAssetSubmitSuccess(false))
      await dispatch(setCurrentAsset(currAsset))
      dispatch(handleLoading(false))
      return currAsset;
    }
    catch (err: any){
      dispatch(handleLoading(false))
    }
  }
}

//TODO:
// Update Current Seleected Asset - any changes to the form or items this way if they close the tabe items will still exist
export function updateCurrentSelectedAsset(updatedAsset: IAsset, saveDraft: boolean = true): DefaultThunkAction<Promise<any>> { 
  return async (dispatch, getState) => {
    if(saveDraft){
      dispatch(handleLoading(true))
    }
    try {
      //TODO: Maybe had an edited item
      await dispatch(setCurrentAsset(updatedAsset))
      if(saveDraft){
        await dispatch(saveDraftAuthoredAsset())
      }
      await dispatch(handleLoading(false))
      return []
    } catch (error) {
      // Log.error(error, "Error dispatching: validateToken");
      dispatch(handleLoading(false))

      throw error;
    }
  }
}

//TODO:
// Save currernt Asset darft - this will store the information on the cloud
export function saveDraftAuthoredAsset(): DefaultThunkAction<Promise<any>> { 
  return async (dispatch, getState) => {
    dispatch(handleLoading(true))
    try {
      const accessToken = await dispatch<Promise<string>>(validateToken());
      const { assets } = getState();
      const currentAsset = assets.currentAsset;

      const result = await saveCurrentAsset(accessToken, currentAsset )
      dispatch(setSaveCurrentAsset(result.asset))
      dispatch(handleLoading(false))
      return 
    } catch (error) {
      // Log.error(error, "Error dispatching: validateToken");
      dispatch(handleLoading(false))

      throw error;
    }
  }
}

// TODO:
// Delete Current asset
export function deletedSelectedAuthoredAsset( asset: IAsset): DefaultThunkAction<Promise<any>> { 
  return async (dispatch, getState) => {
    dispatch(handleLoading(true))
    try {
      const { assets } = getState()
      const accessToken = await dispatch<Promise<string>>(validateToken());
      const res  = await deleteDraftAsset( accessToken, asset.Id)
      
      Log.info(res)
      const temp = {
        ...assets.assets
      }
      const newTemp = {} as Record<string, any>

      Object.keys(temp).forEach(key => {
        if(key !== asset.Id){
          newTemp[key] = temp[key]
        }
      })
      
      dispatch(removeAssetFromList(newTemp))
      dispatch(setMessage({message: `Asset: ${asset.AssetStoreItem.Name } was deleted successfully`, severity: 'success'}))
      dispatch(handleLoading(false))
      return []
    } catch (error) {
      dispatch(setMessage({ message: `There was an error: ${error}`, severity: 'error'}))
      dispatch(handleLoading(false))
      throw error;
    }
  }
}

export function uploadAsset(file: File): DefaultThunkAction<Promise<any>> { 
  return async (dispatch, getState) => {
    await dispatch(setAssetProgress(0))
    await dispatch(setAssetUploadLoading(true))
    const accessToken = await dispatch<Promise<string>>(validateToken());
    try 
    {
      const { assets } = getState();
      const currentAsset = assets.currentAsset;

      // Quick checks if data matches what is needed.
      if(!file.name.endsWith(".bbasset")){
        await dispatch(setMessage({message: 'Incorrect file type', severity: 'error'}))
        await dispatch(setAssetUploadLoading(false))
        return
      }
      if(file.size > 1048576 * 2000){
        dispatch(setMessage({ message: `File is larger than the 2GB limit`, severity: 'error'}))
        await dispatch(setAssetUploadLoading(false))
        return
      }

      const presignedPostData = await getPresignedAssetData( accessToken, currentAsset);
      const completedS3Upload = await uploadFile(presignedPostData, file);
      const tempAsset = await uploadAnAsset(accessToken, currentAsset)

      await dispatch(setSaveCurrentAsset(tempAsset.assetVersion))
      await dispatch(setCurrentAsset(tempAsset.assetVersion))
      await dispatch(setAssetUploadLoading(false))
    } 
    catch (error: any) 
    {
      await dispatch(setAssetUploadLoading(false))
      await dispatch(setAssetProgress(0))
      dispatch(setMessage({ message: `There was an error: ${error}`, severity: 'error'}))
     
    }
  }
}

export function uploadPublicResource(file: any, resourceType: string): DefaultThunkAction<Promise<any>> { 
  return async (dispatch, getState) => {
    // await dispatch(handleLoading(true))
    await dispatch(setImageUploading(true))
    const accessToken = await dispatch<Promise<string>>(validateToken());
    try 
    {
      const { assets } = getState();
      const currentAsset:IAsset = assets.currentAsset;

      const result = await uploadAssetPublicResource(accessToken, file, currentAsset, resourceType)
      if(resourceType === 'screenshot'){
        await  dispatch(setAssetScreenShotUrl(result.url))
      }
      const temp: IAsset = {
        ...currentAsset,
      }
      temp.AssetStoreItemPageMedia.Screenshot1Url =  result.url
      await dispatch(setSaveCurrentAsset(temp))

      // if( resourceType === 'thumbnail'){
      //   dispatch(setAssetThumbnailUrl(result.url))
      // }
      dispatch(setMessage({message: `Asset ${resourceType} was uploaded successfully`, severity: 'success'}))
      await dispatch(setImageUploading(false))
      return result
    } 
    catch (error: any) 
    {
      await dispatch(setImageUploading(false))
      dispatch(setMessage({ message: `There was an error: ${error}`, severity: 'error'}))
    }
  }
}

export function sumbitMyAsset(): DefaultThunkAction<Promise<any>> {
  return async (dispatch, getState) => {
    await dispatch(handleLoading(true))
    const accessToken = await dispatch<Promise<string>>(validateToken());
    try 
    {
      const { assets } = getState();
      const currentAsset:IAsset = assets.currentAsset;
      
      await saveCurrentAsset(accessToken, currentAsset )
      const result = await submitAssetDraft(accessToken,currentAsset)
      
      currentAsset.Status = 'SUBMITTED'

      await dispatch(setAssetStatus(currentAsset))
      await dispatch(setAssetSubmitSuccess(true))

      return result
    } 
    catch (error: any) 
    {
      await dispatch(handleLoading(false))
      dispatch(setMessage({ message: `There was an error: ${error}`, severity: 'error'}))
    }
  }
}

export function downloadAsset(assetVerionId: string): DefaultThunkAction<Promise<any>> {
  return async (dispatch, getState) => {
    await dispatch(handleLoading(true))
    try 
    {
      // const { assets } = getState();
      // const currentAsset:IAsset = assets.currentAsset;
      const accessToken = await dispatch<Promise<string>>(validateToken());
      const url = await fetchdownloadAsset(accessToken, assetVerionId);
      dispatch(setAssetDownloadLink(url.url))
      await dispatch(handleLoading(false))
    } 
    catch (error: any) 
    {
      await dispatch(handleLoading(false))
      dispatch(setMessage({ message: `There was an error: ${error}`, severity: 'error'}))
    }
  }

}

export function getAssetSoldCount(assetVerionId: string): DefaultThunkAction<Promise<any>> {
  return async (dispatch, getState) => {
    await dispatch(handleLoading(true))
    try 
    {
      const { assets } = getState();
      const currentAsset:IAsset = assets.assets[assetVerionId];
      const accessToken = await dispatch<Promise<string>>(validateToken());
      const soldCount = await fetchAssetSoldCount(accessToken, currentAsset.AssetStoreItem.Id);
      const temp:IAsset = {
        ...currentAsset,
        SoldCount: soldCount.Count
      }

      await dispatch(setAssetCount(temp))
      return temp
    } 
    catch (error: any) 
    {
      await dispatch(handleLoading(false))
    
    }
  }

}

export function setTagsState(tags: string []): DefaultThunkAction<Promise<any>> {
  return async (dispatch, getState) => {
    await dispatch(handleLoading(true))
    try 
    {
      const { assets } = getState();
      // const currentAsset:IAsset = assets.assets[assetVerionId];
      const currentAsset: IAsset = assets.currentAsset;
      // const accessToken = await dispatch<Promise<string>>(validateToken());
      // const soldCount = await fetchAssetSoldCount(accessToken, currentAsset.AssetStoreItem.Id);
      const temp:IAsset = {
        ...currentAsset
      }

      temp.AssetStoreItemPageMedia.Keywords = tags.join(' ');
      await dispatch(setCurrentAsset(temp))
      return temp
    } 
    catch (error: any) 
    {
      await dispatch(handleLoading(false))
      // dispatch(setMessage({ message: `There was an error: ${error}`, severity: 'error'}))
    }
  }
}