import { AnyAction } from 'redux'
import actions from 'redux/actionTypes.json'

const initialState: mbb.state.IAssets = {
 assets: {},
 currentAsset: null,
 unSavedEditsAsset: null,
 AssetDownloadLink: null
}

const assetReducer = (
  state: mbb.state.IAssets = initialState,
  action: AnyAction
): mbb.state.IAssets => {
  switch (action.type) {
    case actions.assets.SET_ASSETS:
      return {
        ...state,
        assets: action.assets,
      }
    case actions.assets.SET_CURRENT_ASSET:
      return {
        ...state,
        currentAsset: action.asset,
        AssetDownloadLink: null
      }
    case actions.assets.UPDATE_ASSET_URL:
      return {
        ...state,
        currentAsset: {
          ...state.currentAsset,
          Url: action.url
        }
      }
    case actions.assets.SET_ASSET_SCREENSHOT_URL: 
      return { 
        ...state,
        currentAsset: {
          ...state.currentAsset,
          AssetStoreItemPageMedia: {
            ...state.currentAsset.AssetStoreItemPageMedia,
            Screenshot1Url:  action.url
          }
        },
      } 
    case actions.assets.SET_ASSET_THUMBNAIL_URL: 
      return { 
        ...state,
        currentAsset: {
          ...state.currentAsset,
          AssetStoreItemPageMedia: {
            ...state.currentAsset.AssetStoreItemPageMedia,
            ThumbnailMediaUrl:  action.url
          }
        },
      }
    case actions.assets.REMOVE_ASSET: 
      return {
        ...state,
        assets: action.assets
      }
    case actions.assets.ADD_ASSET: 
      return {
        ...state,
        assets: action.assets
      }
    case actions.assets.SAVE_ASSET:
      return {
        ...state,
        assets: {
          ...state.assets,
          [action.asset.Id]: action.asset
        }
      }
    case actions.assets.SET_ASSET_COUNT:
      return {
        ...state,
        assets: {
          ...state.assets,
          [action.asset.Id]: action.asset
        }
      }
    case actions.assets.SET_ASSET_STATUS:
      return {
        ...state,
        assets: {
          ...state.assets,
          [action.asset.Id]: action.asset
        },
        currentAsset: action.asset
      }
    case actions.assets.SET_ASSET_DOWNLOAD_LINK:
      return{ 
        ...state,
        AssetDownloadLink: action.url
      }
    case actions.assets.CLEAR_ASSETS:
      return initialState;
    default:
      return state
  }
}

export default assetReducer
