import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore, {history} from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from '@mui/material'
import theme from './styles/theme'

// FIXME: bug with the first visit of webpage doesn't work with react  router v6
// import { ConnectedRouter } from "connected-react-router";

import Amplify from 'aws-amplify'

import { aws_config } from './constants/amplify_config'
// import { amplify_config_update } from './constants/amplify_config_update' 
// import { createMemoryHistory, History } from 'history'

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: aws_config.cognito.REGION,
    userPoolId: aws_config.cognito.USER_POOL_ID,
    userPoolWebClientId: aws_config.cognito.APP_CLIENT_ID,
  },
  oauth: {
    domain: aws_config.oauth.domain,
    scope: aws_config.oauth.scope,
    redirectSignIn: aws_config.oauth.redirectSignIn,
    redirectSignOut: aws_config.oauth.redirectSignOut,
    responseType: aws_config.oauth.responseType,
  },
  API: {
    endpoints: [
      {
        name: "userURL",
        endpoint: aws_config.apiGateway.URL,
        region: aws_config.apiGateway.REGION,
      },
      {
        name: "assetsURL",
        endpoint: aws_config.assetManagementGateway.URL,
        region: aws_config.apiGateway.REGION,
      },
      {
        name: "bbworldUrl",
        endpoint: aws_config.bbworldGetway.URL,
        region: aws_config.apiGateway.REGION,
      },
      // {
      //   name: "assetsURL", // To overwritte the assetURLs to use PSeudo local 
      //   endpoint: aws_config.pseudoLocalGatway.URL,
      //   region: aws_config.apiGateway.REGION
      // },
      {
        name: "adsURL",
        endpoint: aws_config.adsGetway.URL,
        region: aws_config.apiGateway.REGION,
      }
    ],
  },
})

const { store, persistor } = configureStore()
// const history: History =  createMemoryHistory();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      {/* <ConnectedRouter history={history}> */}
      <Router >
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Router>
      {/* </ConnectedRouter> */}
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
