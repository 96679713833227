import Amplify from "aws-amplify"
//insert the constants here and use from env.
export const aws_config = {
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_GATEWAY_URL,
  },
  bbworldGetway: {
    URL: process.env.REACT_APP_BUILD_BOX_WORLD_GATEWAY_URL,
    REGION: process.env.REACT_APP_REGION,
  },
  assetManagementGateway: {
    URL: process.env.REACT_APP_ASSET_MANAGEMENT_GATEWAY_URL,
    REGION: process.env.REACT_APP_REGION,
  },
  pseudoLocalGatway: {
    URL: process.env.REACT_APP_PSEUDO_LOCAL,
    REGION: process.env.REACT_APP_REGION,
  },
  adsGetway: {
    URL: process.env.REACT_APP_API_AD_MANAGER_URL,
    REGION: process.env.REACT_APP_REGION,
  },
  //TODO: possibly add oauth here
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN_URL,
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN_URL,
    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT_URL,
    responseType: "code",
  },
}

// const isLocalhost = Boolean(
//   window.location.hostname === "localhost" ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === "[::1]" ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
// )

// // Assuming you have two redirect URIs, and the first is for localhost and second is for production
// const [localRedirectSignIn, productionRedirectSignIn] = aws_config.oauth.redirectSignIn.split(",")

// const [localRedirectSignOut, productionRedirectSignOut] = aws_config.oauth.redirectSignOut.split(",")

// const updatedAwsConfig = {
//   ...aws_config,
//   oauth: {
//     ...aws_config.oauth,
//     redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
//     redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
//   },
// }

// Amplify.configure(updatedAwsConfig)
