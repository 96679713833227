import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from './Circular';
import Button from '@mui/material/Button';

export default function SimpleBackdrop(props:any) {
  // const [open, setOpen] = React.useState(false);
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  return (
    <div>
      <Backdrop
        sx={{ color: '#f1f1f1',
          background: 'rgb(241,241,241, .7)',         
          zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.open}
        // onClick={handleClose}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
}