import { API } from 'aws-amplify';
import Log from 'utils/Log';
import Axios, { AxiosResponse } from 'axios';
import AWS from 'aws-sdk';

type ITemplate = mbb.model.ITemplate;
type TemplateOrientation = mbb.model.TemplateOrientation

export type NewTemplate = {
  Name: string;
  description?: string;
  detailedDescription?: string;
  orientation: TemplateOrientation
};

export type DeleteBody = {
  Id: string
}

export enum TemplateResourceType {
  CAROUSEL_IMAGE = 'CAROUSEL_IMAGE',
  PREVIEW_GIF = 'PREVIEW_GIF'
}

export type DeleteCarouselImageBody = {
  Id: string,
  TemplateUrl: string,
  Order: number
}

export const createNewTemplate = (
  token: string,
  body: NewTemplate
): Promise<ITemplate> => {
  return new Promise((resolve, reject) => {
    API.post(`assetsURL`, '/marketplace/template/create', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Token: token,
      },
      response: true,
      body: body,
    })
      .then((res) => {

        //201 status for new entry in data base in elastic
        Log.info(res.data.payload);
        resolve(res.data.payload.result);
      })
      .catch((error) => {
        if (error.response) {
          Log.error(error.response);
          reject(error.response.data.error);
        }
      });
  });
};

export const fetchUserTemplates = (
  token: string
): Promise<Record<string, ITemplate>> => {
  return new Promise((resolve, reject) => {
    API.get(`assetsURL`, '/marketplace/templates', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        // "Content-Type": "application/json", why did this work? .... f corsÍ›
        Token: token,
      },
      response: true, //needs this to get the whole response object
    })
      .then(async (res) => {
        let templateObjects: Record<string, ITemplate> = {};

        res.data.payload.result.forEach((template: ITemplate) => {
          const currId = template.Id;

          templateObjects[currId] = template;
        });
        Log.info(templateObjects);
        resolve(templateObjects);
      })
      .catch((error: any) => {
        if (error.response) {
          reject(error.response.data.error);
        }
      });
  });
};

export const fetchUserAuthoredTemplate = async (
  templateVersionId: string,
  token: string
): Promise<ITemplate> =>
{
  try{ 
    const res = await API.get(`assetsURL`, `/marketplace/template/${templateVersionId}`, {
     headers: {
        'Access-Control-Allow-Origin': '*',
        // "Content-Type": "application/json", why did this work? .... f corsÍ›
        Token: token,
      },
      response: true, //needs this to get the whole response object
    }) 

    return res.data.payload.result as ITemplate;

  } catch(err) {
    throw err;
  }
}

export const getPresignedTemplateData = async (
  token: string,
  template: ITemplate
): Promise<AWS.S3.PresignedPost> => {
  const templateBody = {
    Id: template.Id,
    // templateVersionId: template.Id,
    // templateVersion: template.Version,
  };
  try {
    const res = await API.post(`assetsURL`, '/marketplace/template/preUpload', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Token: token,
      },
      response: true,
      body: templateBody,
    });

    const presignedPostData: AWS.S3.PresignedPost = res.data.payload.TemplateUrl;
    return presignedPostData;
  } catch (err) {

    throw err;
  }
};

export const uploadTemplateFile = async (
  presignedPostData: AWS.S3.PresignedPost,
  file: File
): Promise<AxiosResponse> => {
  try {
    // create a form obj
    let fd = new FormData();

    // append the fields in presignedPostData in formData
    Object.keys(presignedPostData.fields).forEach((key) => {
      fd.append(key, presignedPostData.fields[key]);
    });

    // append the file
    fd.append('file', file);

    const response = await Axios.post(presignedPostData.url, fd);

    return response;
  } catch (error) {
    console.error('Error attempting to upload File: ', error);

    throw error;
  }
};

export const uploadATemplate = async (
  token: string,
  template: ITemplate
): Promise<any> => {
  try {
    const templateBody = {
      Id: template.Id,
      uploadComplete: true
    };

    const res = await API.put(`assetsURL`, '/marketplace/template/upload/complete', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Token: token,
      },
      response: true,
      body: templateBody,
    });
    return res.data.payload;
  } catch (err) {
    if (err) {
      Log.error((err as any).response.data.error);
      throw (err as any).response.data.error;
    }
  }
};

export const saveCurrentTemplate = (
  token: string, 
  template: ITemplate,
): Promise<any> => {
  return new Promise((resolve, reject ) => {

  const body = {
    Id: template.Id,
    Name: template.AssetStoreItem.Name,
    CostInVC: template.CostInVC,
    ClientBuildNumberMin: template.ClientBuildNumberMin,
    ClientBuildNumberMax: template.ClientBuildNumberMax,
    Orientation: template.Orientation,
    Description: template.AssetStoreItemPageMedia.Description,
    DetailedDescription: template.AssetStoreItemPageMedia.DetailedDescription,
    Keywords: template.AssetStoreItemPageMedia.Keywords,
    Carousel: template.AssetStoreItemPageMedia.Carousel,
    TemplateUrl: template.TemplateUrl
  }
  API.put(`assetsURL`, `/marketplace/template/save`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Token: token,
      },
      response: true,
      body: body
    })
    .then((res) => {

      resolve(res.data.payload);
    })
    .catch((error) => {
      if (error) {
        Log.error(error.response.data.error)
        reject(error.response.data.error)
      }
    })
  })

}

export const deleteTemplateAPI = (
  token: string,
  body: DeleteBody
) : Promise<any> => {
  return new Promise((resolve, reject ) => {
    API.del(`assetsURL`, `/marketplace/template`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Token: token,
        },
        response: true,
        body
      })
      .then((res) => {
        
        Log.info(res.data.payload);
        resolve(res.data.payload);
      })
      .catch((error) => {
        if (error) {
          Log.error(error.response.data.error)
          reject(error.response.data.error)
        }
      })
    })
}

export const uploadTemplateFileResources = (
  token: string, 
  file: File,
  template: ITemplate,
  resourceType: TemplateResourceType,
  Order?: string
): Promise<ITemplate> => {

  return new Promise((resolve, reject) => {
    const url = resourceType === "CAROUSEL_IMAGE" ? "carousel" :"previewGif";
    let formData = new FormData()
    formData.append('Id', template.Id)
    formData.append('assetFile', file)
    if(Order) {
      formData.append('Order', Order.toString())
    }
    // console.log("order", Order)
  
    API.put(`assetsURL`, `/marketplace/template/${url}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Token: token,
      },
      response: true,
      body: formData
    })
    .then((res) => {
      // Log.info(res.data.payload);
      
      resolve(res.data.payload.result);
    })
    .catch((error) => {
      if (error) {
        Log.error(error.response.data.error)
        reject(error.response.data.error)
      }
    })
  })
}

export const submitTemplateDraft = (
  token: string,
  template: ITemplate,
) : Promise<any> => {
  return new Promise((resolve, reject ) => {
    API.post(`assetsURL`, `/marketplace/template/submit`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Token: token,
        },
        body: {
          Id: template.Id
        },
        response: true,
      })
      .then((res) => {
        
        Log.info(res.data.payload);
        resolve(res.data.payload);
      })
      .catch((error) => {
        if (error) {
          Log.error(error.response.data.error)
          reject(error.response.data.error)
        }
      })
  })
}

export const fetchdownloadTemplate = ( 
  token: string,
  templateVersionId: string
) : Promise<{url: string, exp: Date}>  => {
  return new Promise((resolve, reject ) => {
    API.get(`assetsURL`, `/marketplace/template/download/${templateVersionId}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Token: token,
        },
        response: true,
      })
      .then((res) => {
        
        Log.info(res.data.payload);
        resolve(res.data.payload);
      })
      .catch((error) => {
        if (error) {
          Log.error(error.response.data.error)
          reject(error.response.data.error)
        }
      })
  })
}

export const deleteTemplateCarouselImage = ( 
  token: string,
  body: DeleteCarouselImageBody
) : Promise<any>  => {
  return new Promise((resolve, reject ) => {
    API.del(`assetsURL`, '/marketplace/template/carousel', {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Token: token,
        },
        body,
        response: true,
      })
      .then((res) => {
        
        Log.info(res.data.payload);
        resolve(res.data.payload);
      })
      .catch((error) => {
        if (error) {
          Log.error(error.response.data.error)
          reject(error.response.data.error)
        }
      })
  })
}


export const saveTemplatePriceWhenLive = (
  token: string, 
  template: ITemplate,
): Promise<any> => {
  return new Promise((resolve, reject ) => {

  const body = {
    Id: template.Id,
    CostInVC: template.CostInVC,
  }

  API.put(`assetsURL`, `/marketplace/template/price`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Token: token,
      },
      response: true,
      body: body
    })
    .then((res) => {
      Log.info(res.data.payload);
      resolve(res.data.payload.result);
    })
    .catch((error) => {
      if (error) {
        Log.error(error.response.data.error)
        reject(error.response.data.error)
      }
    })
  })

}