import { AnyAction } from 'redux';
import actions from 'redux/actionTypes.json';

const initialState: mbb.state.ITemplates = {
  templates: {},
  currentTemplate: null,
  unSavedEditsTemplate: null,
  TemplateDownloadLink: null,
};

const templateReducer = (
  state: mbb.state.ITemplates = initialState,
  action: AnyAction
): mbb.state.ITemplates => {
  switch (action.type) {
    case actions.templates.SET_TEMPLATES:
      return {
        ...state,
        templates: action.templates,
      };
    case actions.templates.SET_CURRENT_TEMPLATE:
      return {
        ...state,
        currentTemplate: action.template,
        TemplateDownloadLink: null,
      };
    case actions.templates.SAVE_TEMPLATE:
      return {
        ...state,
        templates: {
          ...state.templates,
          [action.template?.Id]: action.template,
        },
        currentTemplate: action.template
      };
    case actions.templates.UPDATE_TEMPLATE_URL:
      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          TemplateUrl: action.url,
        },
      };
    case actions.templates.SET_TEMPLATE_DOWNLOAD_LINK:
      return {
        ...state,
        TemplateDownloadLink: action.url,
      };
    case actions.templates.REMOVE_TEMPLATE:
      return {
        ...state,
        templates: action.templates,
      };
    case actions.templates.SET_TEMPLATE_STATUS:
      return {
        ...state,
        templates: {
          ...state.templates,
          [action.template.Id]: action.template,
        },
        currentTemplate: action.template,
      };
    default:
      return state;
  }
};

export default templateReducer;
