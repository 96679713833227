import { SxProps, IconButton, FormHelperText, FormControl, Select, MenuItem } from "@mui/material";
import GenericButton from "common/components/Buttons/GenericButton";
import GenericInputLabel, { CssTextField } from "common/components/Inputs/GenericInputLabel";
import BackDropLoading from "common/components/Loading/BackDropLoading";
import GenericModal, { IModalProps } from "common/components/Modals/GenericModal";
import GenericToolTip from "common/components/ToolTip/GenericToolTip";
import React, { FunctionComponent, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLoadingState } from "redux/ui/uiSelectors";
import styles from "styles/Dashboard.module.scss"
import xIcon from "assets/images/12x12/X.svg"
import { initCreateAuthoredTemplate } from "redux/template/templateThunks";

interface ModalCreateNewTemplateProps {
    
}

export enum TemplateStoreItemType {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT'
}

type Inputs = {
    templateName: string
    templateDescription: string
    templateType: TemplateStoreItemType
  }
  
  
  const selectStyling = {
    select: {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffbd00",
      },
      "&:focus .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffbd00",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffbd00",
      },
    },
  }
  
  // TODO: handle the errors better
  export const CreateNewTemplateForm = React.forwardRef((props: any, ref) => {
    const dispatch = useDispatch()
    const loading = useSelector(getLoadingState)
  
    const { handleModalClose } = props
  
    const navigate = useNavigate()
  
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm<Inputs>()
    const onSubmit: SubmitHandler<Inputs> = async data => {
      
      try {
        const templateId = await dispatch(initCreateAuthoredTemplate(data))
        navigate(`/templates/a/${templateId}/details`)
        handleModalClose()
      } catch (err: any) {}
    }
    return (
      /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
      <div className={styles.createAssetForm}>
        {loading && <BackDropLoading />}
        <div style={{ display: "flex", alignItems: "flex-end", flexDirection: "row-reverse" }}>
          <IconButton onClick={handleModalClose} size='large'>
            <img src={xIcon} alt='close' />
          </IconButton>
        </div>
        <h2 style={{ textAlign: "center", fontSize: "18px", fontWeight: "400", marginTop: "-18px" }}>Create New Template</h2>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.formContent}>
            <div className={styles.inputItems}>
              <GenericToolTip
                label='Template Name'
                title={
                  <div>
                    <p>Alphanumeric name that will be displayed on the main Buildbox Shop page.  A short description of the style of your game often works best! Examples: 3D Space Shooter, Single Player Quiz, or 2D Jungle Platformer</p>
                  </div>
                }
              />
              <CssTextField
                color='primary'
                defaultValue=''
                placeholder='Template Name'
                {...register("templateName", {
                  required: true,
                  maxLength: 30,
                  // pattern: /^[A-Za-z0-9\s!@#$%^&*()_+=-`~\\\]\[{}|';:/.,?><]*$/,
                })}
                size='small'
                error={errors.templateName ? true : false}
                className={styles.inputStyle}
              />
              {errors.templateName ? (
                <FormHelperText
                  id='component-helper-text'
                  sx={{ color: "red", marginLeft: "13px", marginTop: "-1px" }}
                  error={errors.templateName ? true : false}
                >
                  Template Name conditions were not met
                </FormHelperText>
              ) : (
                " "
              )}
            </div>
            <div className={styles.inputItems}>
              <GenericInputLabel htmlFor='outlined-adornment-password' inputLabelStyles={styles.inputLabel}>
                Template Version:
              </GenericInputLabel>
              <CssTextField
                color='primary'
                placeholder='1.0.0'
                defaultValue='1.0.0'
                size='small'
                disabled
              />
            </div>
            <div className={styles.inputItems}>
              <div style={{ width: "200px" }}>
                <GenericToolTip
                  label='Template Orientation'
                  title={
                    <div>
                      <p>
                                        When a user previews your game, does it default to portrait or landscape? Your store page layout reflects this choice.
                                        </p>
                    </div>
                  }
                />
                <FormControl fullWidth>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    style={{ minWidth: "150px" }}
                    placeholder='Choose Type'
                    // className={classes.select}
                    SelectDisplayProps={{ style: { marginLeft: "10px", fontSize: "14px" } }}
                    // SelectDisplayProps={modalStyles}
                    {...register("templateType", { required: true })}
                    error={errors.templateType ? true : false}
                    sx={selectStyling.select}
                  >
                    <MenuItem value={TemplateStoreItemType["LANDSCAPE"]}>LANDSCAPE</MenuItem>
                    <MenuItem value={TemplateStoreItemType["PORTRAIT"]}>PORTRAIT</MenuItem>
                  </Select>
                </FormControl>
                {errors.templateType ? (
                  <FormHelperText
                    id='component-helper-text'
                    sx={{ color: "red", marginLeft: "13px", marginTop: "-1px" }}
                    error={errors.templateType ? true : false}
                  >
                    The template orientation is required
                  </FormHelperText>
                ) : (
                  " "
                )}
              </div>
            </div>
            <div className={styles.inputItems}>
              <GenericToolTip
                label='Description'
                title={
                  <div>
                    <p>
                                    The hover text shown on the main Buildbox Store page. A sentence or two to highlight some of the cool included features, art, or other top selling points of your game.  Example: Tap to shoot your way through 10 levels and more than a dozen unique enemies in this urban sidescroller.
                                    </p>
                  </div>
                }
              />
              <CssTextField
                id='outlined-multiline-flexible'
                // label="Long Description"
                multiline
                minRows={4}
                className={styles.inputStyle}
                placeholder={"Description"}
                error={errors.templateDescription ? true : false}
                inputProps={{
                  style: {
                    padding: "8.5px 14px",
                  },
                }}
                helperText={
                  errors.templateDescription ? (
                    <span style={{ color: "red" }}>The template description contains invalid characters</span>
                  ) : (
                    ""
                  )
                }
                {...register("templateDescription", {
                  maxLength: 1000,
                  // pattern: /^[A-Za-z0-9\s!@#$%^&*()_+=-`~\\\]\[{}|';:/.,?><]*$/,
                })}
              />
              {errors.templateDescription ? (
                <FormHelperText
                  id='component-helper-text'
                  sx={{ color: "red", marginLeft: "13px", marginTop: "-1px" }}
                  error={errors.templateDescription ? true : false}
                >
                  Description conditions were not met
                </FormHelperText>
              ) : null}
            </div>
          </div>
        </form>
        <div className={styles.termsDisclaimer}>
          <p className={styles.termsDisclaimer}>By clicking below I confirm that I have read, understood,</p>
          <p className={styles.termsDisclaimer}>
            and agree to the{" "}
            <a
              className={styles.assetLink}
              href='https://www.buildbox.com/shop-developer-terms/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Buildbox Shop Developer Terms
            </a>
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "30px" }}>
          <GenericButton
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
            variant='filled'
            text='Submit'
            buttonStyles={styles.createAssetButton}
          >
            Next
          </GenericButton>
        </div>
      </div>
    )
  })
 
const ModalCreateNewTemplate: FunctionComponent<ModalCreateNewTemplateProps> = () => {
    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  // const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false)

  const handleModalClose = () => {
    setOpenCreateModal(false)
  }
  const handleModalOpen = () => {
    setOpenCreateModal(true)
  }

  const modalProps: IModalProps = {
    title: "Create Template",
    open: openCreateModal,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose,
    // childComp: <CreateNewAssetForm/>,
    buttonStyle: "filled",
    buttonStyles: styles.buttonUploadModal,
    customContainerStyles: {},
    useButton: true
  }

  return (
    <div>
      <GenericModal {...modalProps}>
        {/* {termsAndConditions ?
          <TermsAndConditions handleModalClose={handleModalClose} setTermsAndConditions={setTermsAndConditions} /> :
         
        } */}
        <CreateNewTemplateForm handleModalClose={handleModalClose} />
      </GenericModal>
    </div>
  )
}
 
export default ModalCreateNewTemplate;