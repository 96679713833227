import react, { useState, useRef} from 'react';
import { 
  Box, 
 } from '@mui/system';
import { Cancel, Tag } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { CssTextField } from '../Inputs/GenericInputLabel';
import { setTagsState } from 'redux/asset/assetThunks';
import { useDispatch } from 'react-redux';
interface IInputTags {
  tags: string[],
  SetTags: Function,
  inputStyles?: any
}

const Tags = (props: any) => {
  const {
    data,
    handleDelete,
  } = props
  return (
    <Box
      sx={{
        background: "#E3E3E3",
        height: "100%", 
        display: "flex",
        padding: "0.4rem",
        margin: "0 0.5rem 0 0",
        justifyContent: "center",
        alignContent: "center",
        // color: "#ffffff",
        color: "#787878",
        borderRadius: "2px",
        border: 1,
        '&:hover': {
          borderColor: "#FFBD00",
          color: 'black',
        },        
      }}
    >
      <Stack direction='row' gap={1}>
        <Typography>{data}</Typography>
        <Cancel
          sx={{ 
            cursor: "pointer",
            '&:hover': {
              color: "#FF6F44"
            },     
          }}
          onClick={() => {
            handleDelete(data);
          }}
        />
      </Stack>
    </Box>
  );
};

const InputTags = (props:any) => {
  const dispatch = useDispatch();

  // const [tags, SetTags] = useState<string[]>([]);
  const {
    tags,
    SetTags,
    inputStyles,
    customBoxStyles
  } = props
  const tagRef = useRef<any>();
  const customBoxStylesR = customBoxStyles || {};

  const handleDelete = (value:string) => {
    const newtags = tags.filter((val:string) => val !== value);
    SetTags(newtags);
  };

  const handleOnSubmit = (e:any) => {
    e.preventDefault();
    if(tagRef.current.value.includes(' ')){
      return
    }
    if(tags === null){
      SetTags([tagRef.current.value.trim()]);
      tagRef.current.value = "";
      return
    }
    if(tags.length < 10){    
      if (tags.find((tag: string) => tag === tagRef.current.value)) return;
      if (tags.find((tag: string) => tag === tagRef.current.value)) return;
      SetTags([...tags, tagRef.current.value.trim()]);
      // dispatch(setTagsState([...tags, tagRef.current.value]))
      tagRef.current.value = "";
    }
  };

  return (
    <Box sx={{ flexGrow: 1, height: "100px" }}>
      <form onSubmit={handleOnSubmit}>
        <CssTextField
        inputRef={tagRef}
        // fullWidth
        // label="Tags: "
        size='small'
        margin='none'
        placeholder={(tags !== null && tags.length < 10)? "Enter tags" : ""}
        className={inputStyles}
      />
       
      </form>
      <Box sx={{ margin: "0 0.2rem 0 0", display: "flex", ...customBoxStylesR }}>
        {tags !== null && tags.map((data: any, index:number) => {
          return (
            <Tags data={data} handleDelete={handleDelete} key={index} />
          );
        })}
      </Box>
    </Box>
  );
}

export default InputTags
