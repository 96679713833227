export const getNavDrawerState = (state: { ui: { navDrawerOpen : boolean}}) => {
  return state.ui.navDrawerOpen;
}

export const getLoadingState = (state: { ui: { loading : boolean}}) => {
  return state.ui.loading;
}

export const getUIMessage = (state: { ui: { uiMessage: mbb.model.IUIMessage }}) => {
  return state.ui.uiMessage
}

export const getUISubmitSuccess = (state: { ui : { assetSubmitSuccess: boolean}}) =>{
  return state.ui.assetSubmitSuccess;
}

export const getAssetUploadingState = ( state: { ui: { assetUploading: boolean }}) => {
  return state.ui.assetUploading
}

export const getImageUPloadingState = ( state : { ui: { imageUploading: boolean }}) => {
  return state.ui.imageUploading
}

export const getAssetUploadProgress = ( state : { ui:  { assetUploadProgress: number }}) => {
  return state.ui.assetUploadProgress
}

export const getUITemplateSubmitSuccess = (state: { ui : { templateSubmitSuccess: boolean}}) =>{
  return state.ui.templateSubmitSuccess;
}

export const getTemplateUploadingState = ( state: { ui: { templateUploading: boolean }}) => {
  return state.ui.templateUploading
}

export const getTemplateUploadProgress = ( state : { ui:  { templateUploadProgress: number }}) => {
  return state.ui.templateUploadProgress
}