
import actions from '../actionTypes.json';
import { AnyAction } from 'redux'

// type ICognitoUser = mbb.model.ICognitoUser
type IAsset = mbb.model.IAsset

export function setUserAssets(assets: Record<string,IAsset> ): AnyAction {
  return {
    type: actions.assets.SET_ASSETS,
    assets,
  }
}

export function setCurrentAsset(asset: IAsset): AnyAction { 
  return {
    type: actions.assets.SET_CURRENT_ASSET,
    asset
  }
}

export function updateAssetUrl(url: string): AnyAction {
  return {
    type: actions.assets.UPDATE_ASSET_URL,
    url
  }
}

export function setAssetScreenShotUrl( url: string ): AnyAction {
  return {
    type: actions.assets.SET_ASSET_SCREENSHOT_URL,
    url
  }
}

export function setAssetThumbnailUrl( url: string ): AnyAction {
  return {
    type: actions.assets.SET_ASSET_THUMBNAIL_URL,
    url
  }
}

export function setSaveCurrentAsset( asset: IAsset ): AnyAction {
  return {
    type: actions.assets.SAVE_ASSET,
    asset
  }
}

export function removeAssetFromList(assets: any): AnyAction{
  return {
    type: actions.assets.REMOVE_ASSET,
    assets
  }
}

export function setAssetStatus(asset: IAsset): AnyAction {
  return {
    type: actions.assets.SET_ASSET_STATUS,
    asset
  }
}

export function setAssetCount(asset: IAsset,): AnyAction {
  return {
    type: actions.assets.SET_ASSET_COUNT,
    asset
  }
}

export function setAssetDownloadLink(url: string):AnyAction {
  return {
    type: actions.assets.SET_ASSET_DOWNLOAD_LINK,
    url
  }
}

export function clearAssets(): AnyAction {
  return {
    type: actions.assets.CLEAR_ASSETS
  }
}

