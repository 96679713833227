import React, { useEffect, useState } from 'react'
import GenericButton from 'common/components/Buttons/GenericButton';
import styles from 'styles/SelectedAssetPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { sumbitMyAsset} from 'redux/asset/assetThunks'
import { currentSelectedAsset } from 'redux/asset/assetSelectors';
import buttonStyles from 'styles/_buttons.module.scss'
import { currentSelectedTemplate } from 'redux/template/templateSelectors';
import { sumbitTemplateForReview } from 'redux/template/templateThunks';
import { useNavigate } from 'react-router-dom';

const SubmitButton = (props: any) => {
  const navigate = useNavigate();
  const dispatch =  useDispatch();
  const currentAsset = useSelector(currentSelectedAsset);
  const currentTemplate = useSelector(currentSelectedTemplate);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  
  const handleSubmit = async () => {
    if (props.type === 'template') {
      await dispatch(sumbitTemplateForReview())
      navigate('/templates')
    } else {
      dispatch(sumbitMyAsset());
    }
  }
  
  const verifyAssetState = ():boolean => {

    if (currentAsset?.AssetStoreItem.Name.trim() === "" || currentAsset?.AssetStoreItem.Type.trim() ==="") {
      return true
    }

    if(!currentAsset?.Url) {
      return true;
    }

    if(!currentAsset?.AssetStoreItemPageMedia.Screenshot1Url){
      return true
    }

    if( currentAsset?.AssetStoreItemPageMedia.LongDescription.trim() === ""){
      return true
    }

    if(currentAsset?.Status === "SUBMITTED" || currentAsset?.Status === "FOR_SALE" || currentAsset?.Status === "IN_REVIEW"){
      return true
    }

    return false;
  }

  const verifyTemplateState = ():boolean => {

    if (currentTemplate?.AssetStoreItem.Name?.trim() === "" || currentTemplate?.Orientation?.trim() ==="") {
      return true
    }

    if(!currentTemplate?.TemplateUrl ) {
      return true;
    }

    if(!currentTemplate?.AssetStoreItemPageMedia.PreviewGif){
      return true
    }

    if(currentTemplate?.AssetStoreItemPageMedia.DetailedDescription.trim() === "" ){
      return true
    }

    if(currentTemplate?.Status === "SUBMITTED" || currentTemplate?.Status === "FOR_SALE" || currentTemplate?.Status === "IN_REVIEW" || currentTemplate?.Status === "DELETED"){
      return true
    }

    return false;
  }

  useEffect(() => {
    const temp = props.type === 'template' ? verifyTemplateState() : verifyAssetState()
    setDisableSubmit(temp)
  }, [currentAsset, currentTemplate])

  return (
    <GenericButton 
      disabled={disableSubmit}
      variant="outlined" 
      onClick={handleSubmit} 
      buttonStyles={ !disableSubmit ?  buttonStyles.submitAssetButton : buttonStyles.submitAssetButtonDisabled }>
        Submit
    </GenericButton>
  )
}

export default SubmitButton
