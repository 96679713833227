import actions from '../actionTypes.json'
// import { DefaultThunkAction } from "../shared";
import { AnyAction } from "redux";

export function setNavDrawer(open: boolean): AnyAction {
    return {
      type: actions.UI.SET_OPEN_NAV_DRAWER,
      open,
    };
}

export function setLoading(loading: boolean) : AnyAction {
  return {
    type: actions.UI.SET_LOADING,
    loading
  }
}

export function setMessage(uiMessage: mbb.model.IUIMessage) : AnyAction {
  return {
    type: actions.UI.SET_MESSAGE,
    uiMessage
  }
}

export function removeMessage() : AnyAction {
  return {
    type: actions.UI.REMOVE_MESSAGE
  }
}

export function setAssetSubmitSuccess(success: boolean): AnyAction {
  return{
    type: actions.UI.SET_ASSET_SUBMIT,
    success
  }
}

export function setUIInitialState(): AnyAction {
  return{ 
    type: actions.UI.RESET_UI,
  }
}

export function setAssetUploadLoading(loading: boolean):AnyAction {
  return {
    type: actions.UI.ASSET_UPLOADING,
    loading
  }
}

export function setImageUploading(loading: boolean):AnyAction {
  return {
    type: actions.UI.IMAGE_UPLOADING,
    loading
  }
}

export function setAssetProgress(progress: number):AnyAction {
  return {
    type: actions.UI.SET_ASSET_PROGRESS,
    progress
  }
}

export function setEarningsTableLoading(loading: boolean):AnyAction {
  return {
    type: actions.UI.SET_EARNINGS_LOADING,
    loading
  }
}

export function setTemplateSubmitSuccess(success: boolean): AnyAction {
  return{
    type: actions.UI.SET_TEMPLATE_SUBMIT,
    success
  }
}

export function setTemplateUploadLoading(loading: boolean):AnyAction {
  return {
    type: actions.UI.TEMPLATE_UPLOADING,
    loading
  }
}

export function setTemplateProgress(progress: number):AnyAction {
  return {
    type: actions.UI.SET_TEMPLATE_PROGRESS,
    progress
  }
}
