import Log from "utils/Log"
// import { AnyAction } from "redux"
import { Auth } from "aws-amplify"
import { push } from "connected-react-router"
import { DefaultThunkAction } from "../shared"
import { setMessage, setUIInitialState } from "../ui/uiActions"
import { ISignUpInputs } from "../../pages/LandingPage/components/SignUp"
// import { ILoginInputs } from "../../pages/LandingPage/components/Login"
// import { IForgetPassword } from './../../pages/LandingPage/components/ForgetPassword';
import { handleLoading } from "../ui/uiThunks"
import routes from "common/constants/routes.json"
import { createUser, getUserAttributes, fetchUserScreenName, updateUserScreenName, fetchTokensforCode } from "api/auth"
import {
  getCognitoUser,
  checkTokenExpiration,
  getRefreshedCognitoTokens,
  refreshWebAccessToken,
} from "../../utils/CognitoUtils"
import { setUserScreenName, setUser, setAuthTokens, clearUser, setUsername } from "./authActions"
import { clearAssets } from "redux/asset/assetActions"

type ICognitoUser = mbb.model.ICognitoUser

export function loginUser(loginCredentials: any, loginHere: boolean = false): DefaultThunkAction<Promise<void>> {
  return async dispatch => {
    dispatch(handleLoading(true))
    try {
      if(loginHere) {await Auth.signIn(loginCredentials.username, loginCredentials.password)}
      const cognitoUserInfo: ICognitoUser = await getCognitoUser()

      const { accessToken, refreshToken } = cognitoUserInfo

      const response = await createUser(accessToken)

      const { user, userCreated } = response.payload

      const userAttributes = await getUserAttributes(accessToken)

      const { email, name, username, locale } = userAttributes

      const userScreenName = await fetchUserScreenName(accessToken)

      dispatch(setUserScreenName(userScreenName))

      const userObject = {
        username: username,
        name: name,
        uuid: user.Id,
        email: email,
        screenName: userScreenName || "",
        country: locale ? userAttributes.locale : "United States of America",
        isNewUser: userCreated,
      }

      const tokenObject = {
        refreshToken,
        accessToken,
      }

      // const productType = sessionStorage.getItem("websocket-product")
      //   ? sessionStorage.getItem("websocket-product")
      //   : sessionStorage.getItem("productType")

      // sessionStorage.setItem("websocket-accessToken", accessToken)
      // sessionStorage.setItem("websocket-refreshToken", refreshToken)
      // sessionStorage.setItem("websocket-email", email)
      // sessionStorage.setItem("websocket-username", username)
      dispatch(setUser(userObject))

      dispatch(setAuthTokens(tokenObject))

      dispatch(handleLoading(false))
    } catch (error: any) {
      dispatch(handleLoading(false))
      if (
        error === "UserLambdaValidationException: PreAuthentication failed with error Your account needs to be verified, but we had a problem sending the verification email. Please try again later."
      ) {
          dispatch(setMessage({ message: `Your account still needs to be verified. A confirmation code has been sent to your email. Please click the link in the email to complete verification and activate your account."`, severity: "error" }))
      } else if (error === "NotAuthorizedException: Incorrect username or password.") {
        dispatch(setMessage({ message: `Incorrect username or password.`, severity: "error" }))
      } else if ( error === "UserLambdaValidationException: PreAuthentication failed with error Your account still needs to be verified. A confirmation code has been sent to your email. Please click the link in the email to complete verification and activate your account.") {
        dispatch(setMessage({ message: `Your account still needs to be verified. A confirmation code has been sent to your email. Please click the link in the email to complete verification and activate your account.`, severity: "error" }))
      }
      else {
        dispatch(setMessage({ message: `${error}`, severity: "error" }))
      }
    }
        
  }
}

export function logout(url?: string): DefaultThunkAction<Promise<void>> {
  // const previousRoute = sessionStorage.getItem("previousRoute")
  return async dispatch => {
    try {
      await Auth.signOut();
      // dispatch(clearAccount());
      dispatch(clearUser());
      dispatch(setUIInitialState());
      dispatch(clearAssets())
      
      localStorage.clear();
      sessionStorage.clear();
    } catch (e) {
      dispatch(setMessage({ message: `Oops Something Went Wrong, attempt signing out again`, severity: "error" }))
      throw e
    }
  }
}

export function clearUserContentInformation():  DefaultThunkAction<Promise<void>> {
  // const previousRoute = sessionStorage.getItem("previousRoute")
  return async dispatch => {
    try {
      // dispatch(clearAccount());
      dispatch(clearUser());
      dispatch(setUIInitialState());
      dispatch(clearAssets())
      
      localStorage.clear();
      sessionStorage.clear();
    } catch (e) {
      dispatch(setMessage({ message: `Oops Something Went Wrong, attempt signing out again`, severity: "error" }))
      throw e
    }
  }
}

export function validateToken(): DefaultThunkAction<Promise<any>> {
  return async (dispatch, getState) => {
    // dispatch(handleLoading(true))

    try {
      const { auth } = getState()
      Log.info("info auth", auth)
      const { accessToken, refreshToken } = auth
      let tokenIsExpired = await checkTokenExpiration(accessToken)
      Log.trace(tokenIsExpired, "Validate token: is token expired?")

      if (tokenIsExpired || !accessToken) {
        const refreshedToken = await dispatch(refreshTokens(refreshToken))
        return refreshedToken
      } else {
        // dispatch(handleLoading(false))
        // Log.info("", "access token is looking ok");
        return accessToken
      }
    } catch (error) {
      // dispatch(handleLoading(false))

      throw error
    }
  }
}

export function refreshTokens(refreshToken: string): DefaultThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    dispatch(handleLoading(true))
    try {
      const { auth } = getState()
      const { cognitoClientId, clientSecret } = auth
      let newToken = null

      if (cognitoClientId && clientSecret) {
        newToken = await getRefreshedCognitoTokens(refreshToken, cognitoClientId, clientSecret)
      } else {
        newToken = await refreshWebAccessToken()
      }

      const tokenObject = {
        accessToken: newToken,
        refreshToken: refreshToken,
      }
      dispatch(setAuthTokens(tokenObject))
      dispatch(handleLoading(false))
      // dispatch(setLoginWorkState(LoginState.None));
      return newToken
    } catch (error: any) {
      dispatch(handleLoading(false))
      Log.error(error, "Error dispatching: refreshTokens")
      // dispatch(setLoginWorkState(LoginState.Unauthorized));

      if (error.message) {
        throw error.message
      } else {
        if (error === "not authenticated") {
          error.message `Your session has expired. Please sign in and try again` 
        }
        dispatch(push(routes.LOGIN))
        throw error
      }
    }
  }
}

export function handleUpdateUserScreenName(screenName: string): DefaultThunkAction<Promise<void>> {
  return async dispatch => {
    dispatch(handleLoading(true))
    const accessToken = await dispatch<Promise<string>>(validateToken())
    try {
      const newScreenName = await updateUserScreenName(accessToken, screenName)
      dispatch(setUserScreenName(newScreenName))
      dispatch(handleLoading(false))
      dispatch(setMessage({ message: `Your creator name is now - ${newScreenName} `, severity: "success" }))
      return newScreenName
    } catch (error: any) {
      dispatch(handleLoading(false))
      let err
      if (error.metadata.errorcode === "ERROR_SCREEN_NAME_IN_USE") {
        err = "This Creator Name is already taken. Please choose a different one."
      }
      if (error.metadata.errorcode === "ERROR_SCREEN_NAME_INAPPROPRIATE") {
        err = error.metadata.message
      }
      if (error.metadata.errorcode === "ERROR_INVALID_SCREEN_NAME") {
        err =
          "Creator Name must: be 3-25 characters long, only contain letters, numbers, dashes, periods, or underscores"
      }
      dispatch(setMessage({ message: `${err}`, severity: "error" }))
      throw error.metadata.errorcode
    }
  }
}

//setting Username for confirmation
export function handleNewUsername(username: string): DefaultThunkAction<Promise<void>> {
  return async dispatch => {
    dispatch(setUsername(username))
  }
}

//New User Creation
//TODO: Sign up + create a new user
export function handleNewUserRegistration(createUser: ISignUpInputs): DefaultThunkAction<Promise<void>> {
  return async dispatch => {
    dispatch(handleLoading(true))
    try {
      Log.info("this is the create user obj", createUser)
      const newUser = await Auth.signUp({
        username: createUser.username,
        password: createUser.password,
        attributes: {
          email: createUser.email,
          name: createUser.name,
        },
      })
      const username = createUser.username
      dispatch(handleLoading(false))
      dispatch(handleNewUsername(username))
      Log.info("here is the newUser from handleNewUserReg", newUser)
    } catch (error) {
      //handleloading false
      dispatch(handleLoading(false))
      throw error
    }
  }
}

export function ssoTokenLogin(
  token: string,
  goToPlansPage: boolean,
  planView: string
): DefaultThunkAction<Promise<void>> {
  return async dispatch => {
    dispatch(handleLoading(true))
    try {
      const response = await fetchTokensforCode(token)

      Log.info(response, "Response from fetchTokensforCode")
      const { 
        //userId, 
        //email,
        accessToken, 
        // clientId, 
        // refreshToken, 
        // clientSecret 
      } = response
      const userAttributes = await getUserAttributes(accessToken)
      Log.info(userAttributes, "Response from userAttributes")

      // const userScreenName = await fetchUserScreenName(accessToken)

      // const userObject = {
      //   username: userAttributes.username,
      //   name: userAttributes.name || email,
      //   uuid: userId,
      //   screenName: userScreenName || "",
      //   email: userAttributes.email || email,
      //   isNewUser: false,
      // }

      // const tokenObject = {
      //   refreshToken,
      //   accessToken,
      // }

      Log.info(response, "fetchTokensforCode response")
      //FIXME: need hti sback in
      // const gaClientId = await getGAClientId();

      // dispatch(setGAClientId(gaClientId));

      // //set the user object here
      // dispatch(setUserScreenName(userScreenName));
      // dispatch(setUser(userObject));

      // dispatch(setCognitoClientInfo(clientId, clientSecret));
      // dispatch(setAuthTokens(tokenObject));

      // dispatch(setFromSSORedirect(true));
      // dispatch(setLoggingInfromClient(LoginClientType.Client));
      // dispatch(setLoginWorkState(LoginState.None));

      // if (goToPlansPage) {
      //   if (planView && planView.length) {
      //     dispatch(setProductType(planView));
      //     dispatch(replace(`${routes.PURCHASE_PLANS}/${planView}`));
      //   } else {
      //     dispatch(push(routes.PURCHASE_PLANS));
      //   }
      // }
    } catch (e) {
      Log.error(e, "Error dispatching: ssoTokenLogin")

      // if (e.response && e.response.status === 401) {
      //   dispatch(setLoginWorkState(LoginState.Unauthorized));
      // } else {
      //   dispatch(setLoginWorkState(LoginState.ServerError));
      // }
      //redirect user to web login
      dispatch(push(routes.LOGIN))
    }
  }
}
