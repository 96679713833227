// import actions from '../actionTypes.json'
import { DefaultThunkAction } from "../shared";
// import { AnyAction } from "redux";
import { setNavDrawer, setLoading } from './uiActions'

export function switchNavDrawer(
  open: boolean
):DefaultThunkAction<Promise<void>> { 
  return async (dispatch) => {
    // const { ui } = getState();
    dispatch(setNavDrawer(open))
  }
}

export function handleLoading(
  loading: boolean
  ):DefaultThunkAction<Promise<void>> { 
    return async (dispatch) => {
      // const { ui } = getState();
      dispatch(setLoading(loading))
    }
  }

// export function handleAssetUploadError {

// }